import React, { useState, useEffect } from 'react';


const Success = ({ success, setSuccess }) => {
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    // Hide the error message after 5 seconds
    const timer = setTimeout(() => {
      setIsVisible(false);
      // Set error to null after hiding
      setSuccess(null);
    }, 5000);

    // Clean up the timer to avoid memory leaks
    return () => clearTimeout(timer);
  }, [success, setSuccess]);

  return (
    <div>
      {isVisible && (
        <div className='successText'>
          {success || 'An error occurred'} 
        </div>
      )}
    </div>
  );
};



export default Success;