import React, { useEffect, useState } from 'react';

import axios from "axios";
import Card2 from "./Card2"
import Card4 from "./Card4"
import Upload from "./Upload"
import InventoryTips from "./InventoryTips";
import { MdAddAPhoto } from "react-icons/md";
import { FiSearch } from "react-icons/fi";
import { BiFilter } from "react-icons/bi";
import { TiArrowUnsorted } from "react-icons/ti";
import { MdOutlineKeyboardArrowLeft, MdOutlineKeyboardArrowUp, MdOutlineKeyboardArrowDown} from "react-icons/md";
import './InventoryTips.css'

export default function Home() {
    const [toys, setToys] = useState([]);
    const [newToy, setNewToy] = useState(true)
    const [icon, setIcon] = useState(false)
    const [openCard, setOpenCard] = useState(false)
    const [openCardObj, setOpenCardObj] = useState()
    const [settings, setSettings] = useState([{"toysLimit": 1, "rotationToysNumber": 1, "id": 1, "toyTypes": [1]}]);
    const [filterDropDown, setFilterDropDown] = useState(false)
    const [filterDateDropDown, setFilterDateDropDown] = useState(false)
    const [filterTypeDropDown, setFilterTypeDropDown] = useState(false)
    const [sortDropDown, setSortDropDown] = useState(false)
    const [filterDate, setFilterDate] = useState('All time')
    const [filterType, setFilterType] = useState([])
    const [sortBy, setSortBy] = useState('Newest to Oldest')
    const [search, setSearch] = useState('')
    const [filteredAndSortedToys, setFilteredAndSortedToys] = useState(toys);
    const [excluded, setExcluded] = useState(false)
    const [keep, setKeep] = useState(false)
    const [showTips, setShowTips] = useState(true)

    const loadImages = async () => {
        try {
            const config = {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${localStorage.getItem("authToken")}`,
                },
            };
            const {data} = await axios.get('/api/private/getusertoys', config);
            setToys(data)  
        } catch (err) {
            console.error(err);
        }
     
    };

    const loadSettings = async () => {
        try {
            const config = {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${localStorage.getItem("authToken")}`,
                },
              };
            let {data} = await axios.get('/api/private/getsettings', config);
            
            
            setSettings(data)  
            // console.log(settings)
        } catch (err) {
            console.error(err);
        }
    };

  
    const printToys = filteredAndSortedToys.map((item, index) =>  {
        return (
            <Card2
                index={toys.length-index}
                item={item}
                key={item._id}
                openCard={openCard}
                setOpenCard={setOpenCard}
                openCardObj={openCardObj}
                setOpenCardObj={setOpenCardObj}
                newToy={newToy}
                setNewToy={setNewToy}
            />
        )
    })

    useEffect(() => {
        loadImages();
        loadSettings()
  
    }, [newToy]);

    useEffect(() => {
        setFilteredAndSortedToys(toys)
    }, [toys]);

    
    const showUpload  = () =>  {
        setIcon(!icon)
    }
//filterSortSearch
    const handleFilterDropDown = () => {
        setFilterDropDown(!filterDropDown)
        if(sortDropDown === true){
            setSortDropDown(false)
        }
    }
    const handleFilterDateDropDown = () => {
        setFilterDateDropDown(!filterDateDropDown)
    }
    const handleFilterTypeDropDown = () => {
        setFilterTypeDropDown(!filterTypeDropDown)
    }

    const handleSortDropDown = () => {
        setSortDropDown(!sortDropDown)
        if(filterDropDown === true){
            setFilterDropDown(false)
        }
    }

    const handleChangeFilterDate = (event) =>{
        setFilterDate(String([event.target.value]))
    }
    const handleChangeFilterType = (event) =>{
        let type = event.target.value
        if(!filterType.includes(type)){
            // console.log("not includes")
            setFilterType([...filterType, type])
        }else if(filterType.includes(type)){
            // console.log("includes")
            const index = filterType.indexOf(type)
            let newFilterType = [...filterType]
            newFilterType.splice(index, 1)
            setFilterType(newFilterType)
        }
    }
    const handleChangeSort = (event) => {
        setSortBy(String([event.target.value]))
    }

    const handleChangeSearch = (event) =>{
        setSearch([event.target.value])
        // console.log([event.target.value])
    }

    const handleChangeExcluded = () => {
        setExcluded(!excluded)
    }
    const handleChangeKeep = () => {
        setKeep(!keep)
    }

    useEffect(() => {//handle Excluded filter checkbox  check uncheck state
        if(excluded && !openCard && filterDropDown){
            var checkbox = document.getElementById('excluded')
            setTimeout(() => {
                checkbox.checked = true
            }, 50); 
        }
    }, [excluded, openCard, filterDropDown]);

    useEffect(() => {//handle Kept in rotation filter checkbox  check uncheck state
        if(keep && !openCard && filterDropDown){
            var checkbox = document.getElementById('keep')
            setTimeout(() => {
                checkbox.checked = true
            }, 50); 
        }
    }, [keep, openCard, filterDropDown]);

    useEffect(() => {//handle sort radio buttons check uncheck state
        if(sortDropDown){
            var radio = document.getElementById(sortBy)
            setTimeout(() => {
                radio.checked = true
            }, 50);
        }
        // console.log(`177 Warning`)
    }, [sortDropDown, sortBy]);

    useEffect(() => {
        if(filterDropDown){
            if(filterDate){
                var radio = document.getElementById(filterDate)
            
                if(filterDateDropDown){
                    radio.checked = true
                }
            }
            
                
            
            filterType.forEach(el =>{

                var checkbox = document.getElementById(el)
                if (checkbox) {
                    checkbox.checked = true;
                  }
            })
        }

    }, [filterDropDown, filterDateDropDown, filterDate, filterType]);

    const handleFilterReset = async () =>{

        var radio = document.getElementById(filterDate)
        if(radio){
            radio.checked = false
        }
        
        setFilterDate('All time')
        filterType.forEach(el =>{
            var checkbox = document.getElementById(el)
            if (checkbox) {
                checkbox.checked = false;
              }
        })

        setFilterType([])
        setSortBy('Newest to Oldest')
        setFilterDate('All time')
        setFilterDropDown(false)
        setSortDropDown(false)
        
        setFilteredAndSortedToys(toys)
        setExcluded(false)
        setKeep(false)
    }
    
   
    

    useEffect(() => {
        //keep in rotation
        let toysAfterKeepFilter
        if(!keep){
            toysAfterKeepFilter = toys
        }else if(keep){
            toysAfterKeepFilter = toys.filter((item) => item.favoriteToy === true)
        }
        
        //excluded
        let toysAfterExcludedFilter
        if(!excluded){
            toysAfterExcludedFilter = toysAfterKeepFilter
        }else if(excluded){
            toysAfterExcludedFilter = toysAfterKeepFilter.filter((item) => item.excludedToy === true)
        }
   



        // filterDate
        let filteredByDate = toysAfterExcludedFilter
        const now = new Date()
        const filterByDate = (days) => toysAfterExcludedFilter.filter((item) => {
            return (((new Date(item.createdAt)).getTime()) > (now.getTime() - days*86400000))
        })
        if(filterDate === "All time"){
            // console.log("All time");
            filteredByDate = toysAfterExcludedFilter
        }else if(filterDate === 'Last 24 hours'){
            // console.log("Last 24 hours!");
            filteredByDate = filterByDate(1)
        }else if(filterDate === 'Last 7 days'){
            // console.log("Last 7 days");
            filteredByDate = filterByDate(7)
        }else if(filterDate === 'Last month'){
            // console.log("Last month");
            let daysInPrivMonth = new Date(new Date().getFullYear(),new Date().getMonth(),0).getDate()
            filteredByDate = filterByDate(daysInPrivMonth)
        }else if(filterDate === 'Last year'){
            // console.log("Last year");
            filteredByDate = filterByDate(365)
        }
        // filterType
        let filteredByDateAndType
        if(filterType.length === 0){
            filteredByDateAndType = filteredByDate
        }else{
            filteredByDateAndType = filteredByDate.filter(el => filterType.includes(el.type))
        }
        
        //sortToys
        let sortedArray = filteredByDateAndType
        if(sortBy === 'Newest to Oldest'){
            sortedArray = filteredByDateAndType.slice().sort(function(a, b) {
                var c = new Date(a.createdAt);
                var d = new Date(b.createdAt);
                return d-c;
            });
        }else if(sortBy === 'Oldest to Newest'){
   
            sortedArray = filteredByDateAndType.slice().sort(function(a, b) {
                var c = new Date(a.createdAt);
                var d = new Date(b.createdAt);
                return c-d;
            });
        }else if(sortBy === 'A to Z'){
 
            sortedArray = filteredByDateAndType.slice().sort(function (a, b) {
                if (a.title < b.title) {
                  return -1;
                }
                if (a.title > b.title) {
                  return 1;
                }
                return 0;
              });
            
        }else if(sortBy === 'Z to A'){
            sortedArray = filteredByDateAndType.slice().sort(function (a, b) {
                if (a.title < b.title) {
                  return 1;
                }
                if (a.title > b.title) {
                  return -1;
                }
                return 0;
              });
            
        }else if(sortBy === 'By type'){
            
            sortedArray = filteredByDateAndType.slice().sort(function (a, b) {
                if (a.type < b.type) {
                  return -1;
                }
                if (a.type > b.type) {
                  return 1;
                }
                return 0;
              });
        }



        //search
        let searchResults
        if(search){
            searchResults = sortedArray.filter(el =>{
                let searchLowerCase = search.toString().toLowerCase()
                let titleLowerCase = (el.title).toString().toLowerCase()
                return titleLowerCase.includes(searchLowerCase)
            })
        }else{
            searchResults = sortedArray
        }
        
        
        // setFilteredAndSortedToys(sortedArray)
        setFilteredAndSortedToys(searchResults)
    }, [excluded, keep, filterDate, filterType, sortBy, search,toys]);
    // const filterAndSortToys = () => {
        
    // }
 

    return (
        <div>
            {openCard ? 
            <div>
                <Card4 
                    item={openCardObj}
                    openCard={openCard}
                    setOpenCard={setOpenCard}
                    newToy={newToy}
                    setNewToy={setNewToy}
                    settings={settings}
                />
            </div>:
            <div>
                <div className='text--align'>
                    <p >{toys.length} out of {settings[0].toysLimit}</p>
                </div>
                {icon ? <div>
                    <Upload newToy={newToy} toys={toys} setNewToy={setNewToy} icon={icon} setIcon={setIcon} settings={settings}/></div> : 
                <div className='text--align'>
                    <p>Click icon bellow to add more toys.</p>
                    <MdAddAPhoto onClick={showUpload} color="#363951" size={70}/>
                </div>}
                
                <div >
                    
                    <div className="sort-by-form ">
                        <div className='searchFilerSort'>
                            <div className='searchFilerSort--item'>
                                <FiSearch  size={30} className='searchFilerSort--icon'/>
                                <input type='text' className='searchInput' size={5} onChange={handleChangeSearch} value={search}></input>
                                {/* <p className='searchFilerSort--text'>Search</p> */}
                            </div>
                            <div className='searchFilerSort--item' >
                                <BiFilter size={30} className=' searchFilerSort--icon' onClick={handleFilterDropDown}/>
                                {/* <p className='searchFilerSort--text'></p> */}
                                <TiArrowUnsorted size={30} className=' searchFilerSort--icon' onClick={handleSortDropDown}/>
                                {/* <p className='searchFilerSort--text'>Sort</p> */}
                            </div>
                            {/* <div className='searchFilerSort--item'><TiArrowUnsorted size={20} className='searchFilerSort--icon'/><p className='searchFilerSort--text'>Sort</p></div> */}
                        </div>
                        {(filterDate !== 'All time' || filterType.length !== 0  || sortBy !== 'Newest to Oldest' || keep || excluded) &&
                        <>
                            <div className='searchFilerSort'>
                                <div></div>
                                <button onClick={handleFilterReset} className='btn--reset'>Reset ({filteredAndSortedToys.length})</button>
                            </div>
                            <div className='searchFilerSort'>
                                {sortBy !== 'Newest to Oldest' &&
                                    <div><h4>Sort: </h4><div className='sortFilterResultButton'>{sortBy}</div></div>
                                }
                                {filterDate !== 'All time' &&
                                    <div><h4>Filter by time: </h4><div className='sortFilterResultButton'>{filterDate}</div></div>
                                }
                               
                            </div>
                            {filterType.length !== 0 &&
                                <div className='searchFilerSort'>
                                    <h4>Filter by type: </h4>
                                    {/* <p>{filterType.join('; ')}</p> */}
                                    <div className='sortFilterResult'>
                                        {filterType.map((el) =>{
                                            let quantityOfThisType = (filteredAndSortedToys.filter(x => x.type === el)).length
                                            return(
                                                <div className='sortFilterResultButton' key={el}>
                                                    {el} ({quantityOfThisType})
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                            }
                             {keep && <div className='sortFilterResultButton'>Kept in rotation</div>}
                                {excluded && <div className='sortFilterResultButton'>Excluded from rotation</div>}
                        </>
                        }

                        
                        {filterDropDown && 
                        <div className='searchFilerSort--dropdown' >
                            <div className='searchFilerSort--dropdown--row' >
                                <MdOutlineKeyboardArrowLeft size={25} onClick={handleFilterDropDown}/>
                                <h4>Filter</h4>
                                {/* <h4 onClick={handleFilterReset}>Reset</h4> */}
                                <h4> </h4>
                            </div>
                            
                            {filterDateDropDown ? 
                            <>
                                <div className='searchFilerSort--dropdown--row searchFilerSort--dropdown--date' onClick={handleFilterDateDropDown}>
                                    <h4>Date</h4>
                                    <div className='searchFilerSort--dropdown--date--viewall'>
                                        {/* <h4>View all</h4> */}
                                        <MdOutlineKeyboardArrowUp size={25}/>
                                    </div>
                                </div>
                                <form onClick={handleChangeFilterDate}>
                                    <label className="form-control" >
                                        <input type="radio" id='Last 24 hours' value="Last 24 hours" name='filterDate'/>
                                        Last 24 hours
                                    </label>
                                    <label className="form-control" >
                                        <input type="radio" id='Last 7 days' value="Last 7 days" name='filterDate'/>
                                        Last 7 days
                                    </label>
                                    <label className="form-control" >
                                        <input type="radio" id='Last month' value="Last month" name='filterDate'/>
                                        Last month
                                    </label>
                                    <label className="form-control" >
                                        <input type="radio" id='Last year' value="Last year" name='filterDate'/>
                                        Last year
                                    </label>
                                    <label className="form-control" >
                                        <input type="radio" id='All time' value="All time" name='filterDate'/>
                                        All time
                                    </label>
                                </form>
                            </> :
                            <div className='searchFilerSort--dropdown--row searchFilerSort--dropdown--date' onClick={handleFilterDateDropDown}>
                                <h4>Date</h4>
                                <div className='searchFilerSort--dropdown--date--viewall'>
                                    {/* <h4>View all</h4> */}
                                    <MdOutlineKeyboardArrowDown size={25}/>
                                </div>
                            </div>
                            }
                            
                           {filterTypeDropDown ?
                           <>
                                <div className='searchFilerSort--dropdown--row searchFilerSort--dropdown--date' onClick={handleFilterTypeDropDown}>
                                    <h4>Type</h4>
                                    <div className='searchFilerSort--dropdown--date--viewall'>
                                        {/* <h4>View all</h4> */}
                                        <MdOutlineKeyboardArrowUp size={25}/>
                                    </div>
                                </div>
                                <div>
                                    {settings[0].toyTypes.map((item) =>  {
                                        return (
                                            <label className="form-control" key={item} onClick={handleChangeFilterType}>
                                                <input type="checkbox" id={item} name={item} value={item}/>
                                                {item}
                                            </label>
                                        )
                                    })}
                                </div>
                           </>:
                                <div className='searchFilerSort--dropdown--row searchFilerSort--dropdown--date' onClick={handleFilterTypeDropDown}>
                                    <h4>Type</h4>
                                    <div className='searchFilerSort--dropdown--date--viewall'>
                                        {/* <h4>View all</h4> */}
                                        <MdOutlineKeyboardArrowDown size={25}/>
                                    </div>
                                </div>
                           }
                           <div className=' searchFilerSort--dropdown--date'>
                                <label className="form-control" onClick={handleChangeExcluded}>
                                    <input type="checkbox" name="excluded" value={excluded} id='excluded'/>
                                    <h4>Excluded from rotation</h4>
                                </label>
                                <label className="form-control" onClick={handleChangeKeep}>
                                    <input type="checkbox" name="keep" value={keep} id='keep'/>
                                    <h4>Kept in rotation</h4>
                                </label>
                                
                           </div>
                           
                            
                            
                            
                            {/* <button className='btn' onClick={filterAndSortToys}> apply</button> */}
                        </div>}
                        {sortDropDown && 
                            <div className='searchFilerSort--dropdown' >
                                <div className='searchFilerSort--dropdown--row'>
                                    <MdOutlineKeyboardArrowLeft size={25} onClick={handleSortDropDown}/>  
                                    <h4>Sort</h4>
                                    <div></div>
                                </div>
                                <form onClick={handleChangeSort}>
                                    <label className="form-control" >
                                        <input type="radio" id='A to Z' value="A to Z" name='sort'/>
                                        A to Z
                                    </label>
                                    <label className="form-control" >
                                        <input type="radio" id='Z to A' value="Z to A" name='sort'/>
                                        Z to A
                                    </label>
                                    <label className="form-control" >
                                        <input type="radio" id='Oldest to Newest' value="Oldest to Newest" name='sort'/>
                                        Oldest to Newest
                                    </label>
                                    <label className="form-control" >
                                        <input type="radio" id='Newest to Oldest' value="Newest to Oldest" name='sort'/>
                                        Newest to Oldest
                                    </label>
                                    <label className="form-control" >
                                        <input type="radio" id='By type' value="By type" name='sort'/>
                                        By type
                                    </label>
                                </form>
                            </div>
                        }
                        
                        
                       
                    </div>
                    
                </div>
                <div className="gallery">
                    {settings[0].toysLimit === 1 && <p>Try to log in, please.</p>}
                    <div className="main--cards">{printToys}</div>
                </div>
            </div>}
            {showTips &&
                <div className='tips--card'>
                    <InventoryTips
                    setShowTips={setShowTips}
                    showTips={showTips}
                    
                    />
                </div>
                
            }
           
        </div>
    );
}
