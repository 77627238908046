import React from 'react'
import { useState } from 'react';
import "../../index.css"

function Toggle(props) {
   
    // const [toggleValue, setTaggleValue] = useState(props.booleanValue)
    const [toggleValueExcluded, setTaggleValueExcluded] = useState(props.excludedToyValue)
    const [toggleValueFavorite, setTaggleValueFavorite] = useState(props.favoriteToyValue)

    // function handleChange(event){
    //     setTaggleValue(!toggleValue)
    // }   
    function handleChangeExcluded(event){
        setTaggleValueExcluded(!toggleValueExcluded)
    }   
    function handleChangeFavorite(event){
        setTaggleValueFavorite(!toggleValueFavorite)
    }   

    const updateExcludedToyValue = async ( value) => {
        try {
            await fetch(`/api/private/updateExcludedToy/${props.item._id}`, {
                credentials: 'include',
                method: 'PUT',
                body: `{"excludedToy": ${value}}`,
                headers: { 'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            },
                
            });
            // setSuccessMsg('Image uploaded successfully');
            props.setNewToy(!props.newToy)
        } catch (err) {
            console.error(err);
            // setErrMsg('Something went wrong!');
        }     
    };
    const updateFavoriteToyValue = async ( value) => {
        try {
            await fetch(`/api/private/updateFavoriteToyProporty/${props.item._id}`, {
                credentials: 'include',
                method: 'PUT',
                body: `{"favoriteToy": ${value}}`,
                headers: { 'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            },
                
            });
            // setSuccessMsg('Image uploaded successfully');
            props.setNewToy(!props.newToy)
        } catch (err) {
            console.error(err);
            // setErrMsg('Something went wrong!');
        }     
    };
    function handleClickExcluded(){
        // console.log('clicked')
        if(toggleValueFavorite === true && toggleValueExcluded === false){
            updateExcludedToyValue(!toggleValueExcluded)
            updateFavoriteToyValue(!toggleValueFavorite)
            setTaggleValueFavorite(!toggleValueFavorite)
        }else{
            updateExcludedToyValue(!toggleValueExcluded)
        }
        
    }
    function handleClickFavorite(){
        // console.log('clicked')
        updateFavoriteToyValue(!toggleValueFavorite)
        if(toggleValueExcluded === true && toggleValueFavorite === false){
            updateExcludedToyValue(!toggleValueExcluded)
            updateFavoriteToyValue(!toggleValueFavorite)
            setTaggleValueExcluded(!toggleValueExcluded)
        }else{
            updateFavoriteToyValue(!toggleValueFavorite)
        }
    }

  return (
    <div>
        <div className='toggle--main'>
            <p>Excluded from rotation</p>
            {/* <p>{(toggleValue)?'true':'false'}</p> */}
            <label className="switch" >
                <input 
                    type="checkbox" 
                    id="toggleValueExcluded"
                    checked={toggleValueExcluded} 
                    onChange={handleChangeExcluded} 
                    name='toggleValueExcluded'
                /> 
                <div className="slider" onClick={handleClickExcluded}></div>
            </label>
        </div>
        <div className='toggle--main'>
            <p>Kept in rotation</p>
            {/* <p>{(toggleValue)?'true':'false'}</p> */}
            <label className="switch" >
                <input 
                    type="checkbox" 
                    id="toggleValueFavorite"
                    checked={toggleValueFavorite} 
                    onChange={handleChangeFavorite} 
                    name='toggleValueFavorite'
                /> 
                <div className="slider" onClick={handleClickFavorite}></div>
            </label>
        </div>
    </div>
    
  )
}

export default Toggle