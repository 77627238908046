import React from 'react'

import { useState } from 'react';
import Category from './Category';

function ListOfToyTypes(props) {
    const  [update, setUpdate] = useState(true)
    const [formData, setFormData] = useState(
        {
            toyTypes:"",
        }
    )
    
    const printToyTypes =() => props.settings[0].toyTypes.map((item, index) =>  {
        return (
            <Category 
                key={index}
                index={index}
                item={item}
                settings={props.settings}
                update={update}
                setUpdate={setUpdate}
                updateToyTypes={updateToyTypes}
                param={props.param}
                setParam={props.setParam}
            />
        )
    })

    // useEffect(() => {
    //     printToyTypes()
    // }, [update]);

 

    function handleChange(event) {
        const {name, value, type, checked} = event.target
        setFormData(prevFormData => {
            return {
                ...prevFormData,
                [name]: type === "checkbox" ? checked : value
            }
        })
    }
    const handleSubmitFile = (e) => {
        e.preventDefault();
        
        let array = props.settings[0].toyTypes
        if(array.includes(formData.toyTypes) || formData.toyTypes.trim() === ''){return}
        let typesArray = array.concat(formData.toyTypes)
        
        updateToyTypes(typesArray);
        props.setParam(!props.param)
        // setUpdate(!update)
        // console.log('handle submit type')
        setFormData({
            toyTypes:"",
        })
    };  

    const updateToyTypes = async (arr) => {
        // console.log(base64EncodedImage)
        try {

            setUpdate(!update)
            await fetch(`/api/private/updateSettings/`, {
                credentials: 'include',
                method: 'PUT',
                body: JSON.stringify({ toyTypes: arr }),
                headers: { 'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            },
                
            });
            // props.setParam(!props.param)
            // setUpdate(!update)
        } catch (err) {
            console.error(err);
            // setErrMsg('Something went wrong!');
        }
    };
    
  return (
    <div className='settings--comp'>
        
   
        <form   className="inventory-screen__form">
            <p className='title--block'>List of toy categories</p>
            <div className="form-group">
                
        
                {printToyTypes()}
                <input
                    type="text"
                    placeholder="add your type"
                    className="border--radius toyTypeInput"
                    
                    
                    onChange={handleChange}
                    name="toyTypes"
                    value={formData.toyTypes}
                />
                <button className="btn btn-primary border--radius" onClick={handleSubmitFile} type="submit">
                    Submit
                </button>
            </div>
        </form>
    </div>

  )
}

export default ListOfToyTypes