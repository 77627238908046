import React from "react";
import "./Card.css"
// import * as CgIcons from 'react-icons/cg';
import { Image } from 'cloudinary-react';
import { IoClose } from "react-icons/io5";
// import { AiOutlineDelete } from "react-icons/ai";
import { BsFillCircleFill } from "react-icons/bs";
import {  useState } from 'react';
// import Toggle from "./Toggle";
import ToggleTwo from "./ToggleTwo";
import { MdAddAPhoto } from "react-icons/md";
import Resizer from "react-image-file-resizer";
export default function Card(props){
    const [fileInputState, setFileInputState] = useState('');
    // const [successMsg, setSuccessMsg] = useState('');
    // const [errMsg, setErrMsg] = useState('');
    const [showInput, setShowInput] =  useState(true)
    const [formData, setFormData] = useState(
        {
            title:props.item.title,
            type:props.item.type,
            caption:props.item.caption,
            // basket:props.item.basket
        }
    )
    const [previewSource, setPreviewSource] = useState('');
    const [selectedFile, setSelectedFile] = useState();
    const [buttonSubmit, setButtonSubmit] = useState(true)
    

    const resizeFile = (file) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      640,
      1000,
      "JPEG",
      60,
      0,
      (uri) => {
        resolve(uri);
      },
      "file"
    );
  });


    const deleteToy = async () => {
        try {
            await fetch(`/api/private/deletetoy/${props.item._id}`, {
                credentials: 'include',
                method: 'DELETE',
                // body: JSON.stringify({ rotationToysNumber: formData.rotationToysNumber }),
                headers: { 'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            },
                
            });
            props.setNewToy(!props.newToy)
        } catch (err) {
            console.error(err);
        }
    }
    const hideCard = () =>{
        props.setOpenCard(!props.openCard)
    }
    
    const hideDesc = () => {
        setShowInput(!showInput)
    }

    function handleChange(event) {
        const {name, value, type, checked} = event.target
        setFormData(prevFormData => {
            return {
                ...prevFormData,
                [name]: type === "checkbox" ? checked : value
            }
        })
    }
    
    const handleSubmitFile = (e) => {
        e.preventDefault();
        buttonSubmitUpdate()
        
        if (!selectedFile){
            updateCard('', formData.title, formData.type, formData.caption);
            hideDesc()
            hideCard()
        }else{

            const reader = new FileReader();
            reader.readAsDataURL(selectedFile);
            reader.onloadend = () => {
            updateCard(reader.result, formData.title, formData.type, formData.caption);
            hideDesc()
            hideCard()
            };
            reader.onerror = () => {
                console.error('AHHHHHHHH!!');
                // setErrMsg('something went wrong!');
            };
        }
     
        
    };

    const updateCard = async ( base64EncodedImage, title, type, caption) => {
        
        try {
            if(base64EncodedImage === undefined){
                base64EncodedImage = ''
            }
    
            await fetch(`/api/private/updatetoy/${props.item._id}`, {
                credentials: 'include',
                method: 'PUT',
                body: JSON.stringify({  data: base64EncodedImage, title: title, type: type, caption: caption }),
                headers: { 'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            },
                
            });
            
            setFileInputState('');
            setPreviewSource('');
            // console.log({ data: base64EncodedImage, firstName: firstName })
        
            // setSuccessMsg('Image uploaded successfully');
            props.setNewToy(!props.newToy)
        } catch (err) {
            console.error(err);
            // setErrMsg('Something went wrong!');
        }     
    };

    const printSelect =(array) => array.map(item =>  {
        return (
                <option key={item} value={item}>{item}</option>
        )
    })
    
    
    const previewFile = (file) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
            setPreviewSource(reader.result);
        };
    };
    const handleFileInputChange = async (e) => {
        try{

            console.log(e.target.files[0])
            console.time('resizeFile')
            const file = await resizeFile(e.target.files[0]);
            console.timeEnd('resizeFile')
            console.log(file)
            previewFile(file);
            setSelectedFile(file);
            setFileInputState(e.target.value);
        }catch (err) {
            console.log(err);
        }
        
    };
    const hiddenFileInput = React.useRef(null);//photo icon
    const handleIcon = event => {//photo icon
        hiddenFileInput.current.click();
    };

    const buttonSubmitUpdate = () => {
        setButtonSubmit(!buttonSubmit)
    }

    return(
        <div className="card4" >

            {showInput ? <></> : 
            <MdAddAPhoto 
            className="icon--close--edit"
            cursor="pointer"
            onClick={handleIcon}
             size={150}/>}

            {previewSource ?
            <img
            src={previewSource}
            alt="chosen"
            className='previewImage '
            style={{ width: '100%' }}
            /> :
            <Image className="card4--img"
                // key={index}
                cloudName={process.env.REACT_APP_CLOUDINARY_NAME}
                publicId={props.item.cloudinaryId}
                // width="640"
                // height="640"
                crop="fill"
            />
            }      
            
            
            <BsFillCircleFill className='icon--close' size={35} color="white" />
            <IoClose className='icon--close' size={35} color="#363951" onClick={hideCard}/>
            {showInput?
            <div className="card--info">
                <h1 className="card--title">{props.item.title}</h1>
                <p className="card--description"><b>Category:</b>&nbsp;{props.item.type}</p>
                {(props.item.basket && props.item.basket.length !== 0) && <p className="card--description"><b>Collections:</b>&nbsp;{props.item.basket.join(' ')}</p>}
                {props.item.caption && <p className="card--description"><b>Notes:</b>&nbsp;{props.item.caption}</p>}
        
                
                {/* <Toggle 
                    booleanValue={props.item.excludedToy} 
                    booleanName='excludedToy' 
                    item={props.item}
                    newToy={props.newToy}
                    setNewToy={props.setNewToy}
                    updateToyValue='updateExcludedToy'
                    >Excluded from rotation
                </Toggle>
                <Toggle 
                    booleanValue={props.item.favoriteToy} 
                    booleanName='favoriteToy' 
                    item={props.item}
                    newToy={props.newToy}
                    setNewToy={props.setNewToy}
                    updateToyValue='updateFavoriteToyProporty'
                    >Keep in rotation
                </Toggle> */}
                <ToggleTwo
                    excludedToyValue={props.item.excludedToy}
                    favoriteToyValue={props.item.favoriteToy}
                    item={props.item}
                    newToy={props.newToy}
                    setNewToy={props.setNewToy}
                > 
                </ToggleTwo>
                <div className="twoButtons">
                    <button className="updateCard btn btn-primary card4--btn" onClick={hideDesc}>Edit</button>
                    <button className="deleteCard btn btn-primary card4--btn" onClick={() => { deleteToy(); hideCard()}}>Delete</button>
                </div>
            </div>:
            <div className="card4--update--submit">
                
                <form onSubmit={handleSubmitFile} className="form--update">
                    <div className="form-group">
                    <input
                    
                    ref={hiddenFileInput}//photo icon
                    id="fileInput"
                    type="file"
                    name="image"
                    onChange={handleFileInputChange}
                    value={fileInputState}
                    className="form-input input--image border--radius"//photo icon
                    
                    
                />
                        
                        <input
                        type="text"
                        className="border--radius"
                        onChange={handleChange}
                        name="title"
                        value={formData.title}
                        placeholder={`Title`}
                        required
                        />
                        {/* <input
                        type="text"
                        className="border--radius"
                        onChange={handleChange}
                        name="type"
                        value={formData.type}
                        placeholder={`type =${props.item.type}`}
                        /> */}

                        <select type="text" name="type" value={formData.type} onChange={handleChange} className="border--radius">
                            {printSelect(props.settings[0].toyTypes)}
                        </select>
                        {/* <select type="text" name="basket" value={formData.basket} onChange={handleChange} className="border--radius">
                            {printSelect(props.settings[0].toysBaskets)}
                        </select> */}

                        <textarea 
                        type="text"
                        className="border--radius"
                        onChange={handleChange}
                        name="caption"
                        value={formData.caption}
                        placeholder={`Notes`}
                        />
                        <button className="btn btn-primary border--radius" type="submit">
                            Submit
                        </button>
                    </div>    
                </form>
            </div>}
        </div>
    )
}