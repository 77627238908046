import * as CiIcons from 'react-icons/ci';
// import React, { useEffect, useState } from 'react';

function CardBasket(props) {
    
    const openBasket = () => {
        props.setShowBasket(!props.showBasket)
        props.setCurrentBasket(props.item)
    }
    let numberToysInABasket = props.toys.filter(el => el.basket.includes(props.item)).length
  return (
    <div className='basket' onClick={openBasket}>
        <div className='basketTitle'>{props.item} ({numberToysInABasket}pcs)</div>
        <div className='basketIcon'>
          <CiIcons.CiShoppingBasket size={200}/>
        </div>
    </div>
  )
}

export default CardBasket