import React, { useEffect, useState } from 'react';
// import { Image } from 'cloudinary-react';
import axios from "axios";
import Card3 from "./Card3"
import Success from './Success';


export default function Home() {
    const [toys, setToys] = useState([]);
    const [allToys, setAllToys] = useState([]);
    const [click, setClick] = useState(true)
    const [buttonSubmit, setButtonSubmit] = useState(true)
    const [lastRotationDate, setLastRotationDate] = useState(null)
    const [success, setSuccess] = useState(false)


    const loadImages = async () => {
        try {
            const config = {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${localStorage.getItem("authToken")}`,
                },
              };
            const {data} = await axios.get('/api/private/getuserrandomtoys', config);
            setToys(data)  
          
            setButtonSubmit(true)
        } catch (err) {
            console.error(err);
           
        }
    };   
    const loadUserToys = async () => {
        try {
            const config = {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${localStorage.getItem("authToken")}`,
                },
              };
            const {data} = await axios.get('/api/private/unexcludedToysAmount', config);
            setAllToys(data)  
        } catch (err) {
            console.error(err);
        }
    };

    
    
    const rotate = async () => {
        try {
            
            const config = {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${localStorage.getItem("authToken")}`,
                },
              };
            const response = await axios.get('/api/private/pickRandomToys', config);
            console.log(response.data.msg)
            setSuccess(response.data.msg)
            setClick(!click)
        } catch (err) {
            console.error(err);
        }
    };
    const getLastRotationDate = async () => {
        try {
            
            const config = {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${localStorage.getItem("authToken")}`,
                },
              };
              const {data} = await axios.get('/api/private/getLastRotationDate', config);
              const date = data[0].lastRotationDate
              if(date ==null){
                setLastRotationDate(null)
              }else{
                setLastRotationDate(new Date(date).toLocaleDateString('en-us', { weekday:"short", month:"short", day:"numeric"}))
              }
        } catch (err) {
            console.error(err);
        }
    };
    const updateLastRotationDate = async () => {
        try {
            
            const config = {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${localStorage.getItem("authToken")}`,
                },
              };
              await axios.get('/api/private/updateLastRotationDate', config);
        } catch (err) {
            console.error(err);
        }
    };


    const printToys = toys.map((item, index) =>  {
        return (
            <Card3
                item={item}
                key={item._id}
                index={index}

            />
        )
    })

    useEffect(() => {
        loadImages();
        loadUserToys();
        getLastRotationDate();
    }, [click]);

    const buttonSubmitUpdate = () => {
        setButtonSubmit(!buttonSubmit)
        
    }

    return (
        <div>

            <div className="gallery">
            {(allToys > 0) ? 
                    ((buttonSubmit) ?
                    <div className='rotatioHeader'>
                        <button className="btn btn-primary custom-btn card4--btn rotatioHeaderButton" type="submit" onClick={() => {rotate(); updateLastRotationDate(); buttonSubmitUpdate()}}>
                            Rotate 
                        </button>
                        {(lastRotationDate != null) &&
                        <fieldset className='rotationLastDate'>
                            <legend className='rotationLastDateText'>last rotation</legend>
                            <p className='rotatioHeaderTextInFrame'>{lastRotationDate}</p>
                        </fieldset>
                        }
                    </div>
                     :
                    <img className='submitButtonInfinity' src='Infinity-2.9s-61px.svg' alt='img'></img>
                    ) :
                    <p>Add toys to the inventory and set up how many toys you going to rotate. Favorite toys do not participate in rotation.</p>
            }
            {(toys.length < 1 && allToys > 0) && 
                <p>Set up how many toys you going to rotate.</p>

            }
                {success ? <Success success={success} setSuccess={setSuccess}/>:<br/>}
                
                <div className="main--cards">{printToys}</div>
            </div>

            
        </div>
    );
}
