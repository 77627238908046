import React from 'react'



function TermsOfUseScreen() {
  return (
    <div className='main'>
        <h1 className='terms--header'>Terms of Use</h1>

        <p className='terms--body'>Welcome to sorttoys.com! These Terms of Use ("Terms") govern your use of our website, including all features, services, and content provided by or through Sort Toys LLC (collectively, the "Service"). By accessing or using the Service, you agree to be bound by these Terms. If you do not agree with any part of these Terms, you may not use the Service.</p>
        <h4 className='terms--body'>1. Acceptance of Terms</h4>
        <p className='terms--body'>
        By using the Service, you affirm that you are of legal age to enter into these Terms, or you have obtained parental or guardian consent to do so. If you are using the Service on behalf of an organization, you are agreeing to these Terms for that organization and represent that you have the authority to bind that organization to these Terms.
        </p>

        <h4 className='terms--body'>2. License and Restrictions</h4>
        <h4 className='terms--body'>2.1. User Content</h4>
        <p className='terms--body'>
        By uploading, submitting, or transmitting any content, including but not limited to photos, images, text, and videos ("User Content") to the Service, you grant Sort Toys LLC a non-exclusive, royalty-free, worldwide, perpetual, and irrevocable license to use, reproduce, modify, adapt, publish, translate, distribute, publicly perform and display, and create derivative works of such User Content. You represent and warrant that you have the necessary rights to grant this license to Sort Toys LLC and that the User Content does not violate any third-party rights.
        </p>

        <h4 className='terms--body'>2.2. Prohibited Conduct</h4>
        <p className='terms--body'>
        You agree not to:
          <ul>
            <li>- Use the Service for any illegal or unauthorized purpose.</li>
            <li> - Violate any applicable laws, regulations, or third-party rights.</li>
            <li>- Upload, post, or transmit any User Content that is unlawful, harmful, threatening, abusive, defamatory, obscene, or otherwise objectionable.</li>
            <li>- Harass, stalk, or harm other users of the Service.</li>
            <li>- Impersonate any person or entity or falsely state or misrepresent your affiliation with a person or entity.</li>
            <li>- Engage in any activity that could disable, overburden, or impair the functioning of the Service.</li>
            <li>- Use any automated system, including but not limited to robots, spiders, or scrapers, to access or interact with the Service.</li>
          </ul>
        </p>

        <h4 className='terms--body'>3. Paid Membership</h4>
        <h4 className='terms--body'>3.1. Subscription</h4>
        <p className='terms--body'>
        Some features of the Service may be available through a paid membership ("Membership"). By purchasing a Membership, you agree to pay the applicable fees and abide by any additional terms or conditions associated with the Membership.
        </p>

        <h4 className='terms--body'>3.2. Billing and Cancellation</h4>
        <p className='terms--body'>
        Membership fees are billed in advance on a recurring basis, as specified during the sign-up process. You authorize Sort Toys LLC to charge your chosen payment method for the applicable fees. You may cancel your Membership at any time, but please note that fees are non-refundable unless otherwise stated.
        </p>

        <h4 className='terms--body'>4. Intellectual Property</h4>
        <p className='terms--body'>
        All content and materials provided on the Service, including but not limited to text, graphics, logos, button icons, images, audio clips, videos, data compilations, and software, are the property of Sort Toys LLC or its licensors and are protected by copyright, trademark, and other intellectual property laws. You may not use, reproduce, distribute, modify, or create derivative works of any content or materials from the Service without prior written consent from Sort Toys LLC or the respective rights holder.
        </p>

        <h4 className='terms--body'>5. Privacy, Personal Information Handling and Security</h4>
        <p className='terms--body'>
        Your privacy is important to us. Please refer to our Privacy Policy  <a href='https://sorttoys.com/privacy'>[link to privacy policy]</a> for information on how we collect, use, and disclose personal information.
        </p>

        <h4 className='terms--body'>5.1. Collection and Use of Personal Information</h4>
        <p className='terms--body'>
        Sort Toys LLC may collect and process personal information in accordance with its Privacy Policy <a href='https://sorttoys.com/privacy'>[link to privacy policy]</a>. By using the Service, you consent to the collection, use, and disclosure of your personal information as described in the Privacy Policy.
        </p>

        <h4 className='terms--body'>5.2. Security Measures</h4>
        <p className='terms--body'>
        Sort Toys LLC takes reasonable measures to protect the security of your personal information. However, no method of transmission or electronic storage is completely secure, and Sort Toys LLC cannot guarantee the absolute security of your personal information. You acknowledge and agree that you provide your personal information at your own risk.
        </p>

        <h4 className='terms--body'>5.3. Notification of Data Breach</h4>
        <p className='terms--body'>
        In the event of a data breach or unauthorized access that compromises the security of your personal information, Sort Toys LLC will make reasonable efforts to promptly notify affected individuals as required by applicable laws and regulations. You agree that Sort Toys LLC will not be liable for any damages or losses arising out of or related to such data breach or unauthorized access.
        </p>

        <h4 className='terms--body'>5.4. Inactive Account and Data Erasure</h4>
        <p className='terms--body'>
        If a user does not actively use the app for a period of one year or more, their data will be automatically deleted from our system. This includes any personal information, preferences, and user-generated content. We value your privacy and take data security seriously, which is why we implement this data retention policy to ensure that we only keep data for active users. If you wish to prevent your data from being deleted due to inactivity, simply log in or use the app within the one-year timeframe to maintain your account and data.
        </p>

        <h4 className='terms--body'>5.5. User Responsibility</h4>
        <p className='terms--body'>
        You are responsible for maintaining the confidentiality of your account credentials and any personal information you provide to Sort Toys LLC. You agree to notify Sort Toys LLC immediately of any unauthorized access or use of your account or any other breach of security.
        </p>

        <h4 className='terms--body'>6. Disclaimer of Warranties</h4>
        <p className='terms--body'>
        THE SERVICE IS PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS, WITHOUT WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED. Sort Toys LLC DISCLAIMS ALL WARRANTIES, WHETHER EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT. Sort Toys LLC DOES NOT WARRANT THAT THE SERVICE WILL BE UNINTERRUPTED, ERROR-FREE, SECURE, OR FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS.
        </p>

        <h4 className='terms--body'>7. Limitation of Liability</h4>
        <p className='terms--body'>
        IN NO EVENT SHALL Sort Toys LLC BE LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, OR PUNITIVE DAMAGES ARISING OUT OF OR IN CONNECTION WITH YOUR USE OF THE SERVICE. 
        Sort Toys LLC'S TOTAL LIABILITY TO YOU FOR ANY CLAIMS ARISING OUT OF OR RELATED TO THE SERVICE SHALL NOT EXCEED THE AMOUNT PAID BY YOU, IF ANY, FOR ACCESSING THE SERVICE.
        </p>

        <h4 className='terms--body'>8. Indemnification</h4>
        <p className='terms--body'>
        You agree to indemnify and hold harmless Sort Toys LLC and its affiliates, officers, directors, employees, agents, and licensors from and against any and all claims, liabilities, damages, losses, costs, expenses, or fees arising out of or in connection with your use of the Service or violation of these Terms.
        </p>

        <h4 className='terms--body'>9. Modifications to the Service and Terms</h4>
        <p className='terms--body'>
        Sort Toys LLC reserves the right to modify or discontinue the Service, temporarily or permanently, with or without notice. Sort Toys LLC may also change these Terms from time to time. We will notify you of any material changes to these Terms, and your continued use of the Service after such changes constitutes your acceptance of the updated Terms.
        </p>

        <h4 className='terms--body'>10. Governing Law and Jurisdiction</h4>
        <p className='terms--body'>
        These Terms shall be governed by and construed in accordance with the laws of [Jurisdiction]. Any disputes arising out of or in connection with these Terms shall be subject to the exclusive jurisdiction of the courts of [Jurisdiction].
        </p>

        <h4 className='terms--body'>11. Severability</h4>
        <p className='terms--body'>
        If any provision of these Terms is held to be invalid, illegal, or unenforceable, the remaining provisions shall continue in full force and effect.
        </p>

        <h4 className='terms--body'>12. Entire Agreement</h4>
        <p className='terms--body'>
        These Terms constitute the entire agreement between you and Sort Toys LLC regarding your use of the Service and supersede any prior or contemporaneous agreements, communications, or understandings, whether oral or written.
        If you have any questions or concerns about these Terms, please contact us at <a href='https://sorttoys.com/contactus'>[link to contact form]</a>.
        </p>
    </div>
  )
}

export default TermsOfUseScreen