import React from 'react'
import { FaYoutube, FaTiktok } from "react-icons/fa";
import {AiFillInstagram} from "react-icons/ai"
import {GiSheep, GiFallingStar, GiRhinocerosHorn} from "react-icons/gi"
import { MdAddAPhoto } from "react-icons/md";
import {IoIosAlbums,IoIosSettings,IoMdSwap} from "react-icons/io"
import { Link } from "react-router-dom";

function FrontPage() {
  return (
    <div className='main'>
        {/* <div>
             <img src='pexels-freestocksorg-245914.jpg' className='frontPage--header'></img>
             <img src='logo-no-background.svg' width={300} className='frontPage--header--logo'></img>
        </div> */}
        <div className='frontPage--section1'>
            <div className='frontPage--section1--div'>
                  <img src='logo-no-background.svg' className='frontPage--section1--logo' alt='img'></img>
                  <h3 className='frontPage--section1--text1'>Toy Rotation Made Simple</h3>
            </div>
            
            <img src='pexels-cottonbro-studio-3661222-sq-600.jpg' className='frontPage--section1--img1 img--rad' alt='img'></img>
            
        </div>
        <div className='frontPage--section3'>
            {/* <h1 className='frontPage--section3--text1'>How to Use</h1>
            <div className='frontPage--section3--block'>
              <h2 className='frontPage--section3--block--first'>First</h2>
              <h3 className='text-howToUse'>Register  to  get  access to the App</h3>
              
              <h2 className='frontPage--section3--block--second'>Second</h2>
              <h3 className='text-howToUse'>Add your toys</h3>
              
              <h2 className='frontPage--section3--block--third'>Third</h2>
              <h3 className='text-howToUse'>App will rotate your toys for you, just click the rotate button</h3>
            </div> */}
            {/* <div className='line'></div> */}
        <div className='frontPage--badgeContainer'>
            <a href="https://apps.apple.com/us/app/sort-toys-toy-rotation-app/id6503460525?itsct=apps_box_badge&amp;itscg=30200" style={{display: 'inline-block', overflow: 'hidden', borderRadius: 13,  height: 78, margin:10, }}><img src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;releaseDate=1719100800" alt="Download on the App Store" style={{borderRadius: 13,  height: 78}}/></a>
            <a href='https://play.google.com/store/apps/details?id=com.denis88.mobileToyRotationApp&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1' style={{position:"relative", top:4}}><img className='google--play--badge'  alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'/></a>
              
        </div>
        <h1 className='frontPage--section2--text1'>About</h1>
        {/* <br/> */}
        <div className='frontPage--section2'>
            <div className='frontPage--section2--div'>
              <p className='frontPage--section2--text2'>When kids have too many choices of toys it can make them feel overwhelmed. Having everything out at once creates clutter that leaves YOU feeling overwhelmed. The answer to both those problems is a toy rotation system.</p>
              <img src='pexels-tatiana-syrikova-3933033-sq-600.jpg' className='frontPage--section2--img1 img--rad' alt='img'></img>
            </div>
            <div className='frontPage--section2--div'>
              <p className='frontPage--section2--text2'>Inspired by Montessori philosophy, toy rotation allows you to bring out a certain number of toys and switch them out at set intervals. How many toys and how often to change them is up to you. Our purpose is to make the "how" of the process easier.</p>
              <img src='pexels-tatiana-syrikova-3932930-sq-600.jpg' className='frontPage--section2--img1 img--rad' alt='img'></img>
            </div>
            <div className='frontPage--section2--div'>
              <p className='frontPage--section2--text2'> Instead of making spreadsheets or keeping long lists of toys, store them in your digital inventory and get a new rotation in seconds. Take the clutter out of your home and have your kids actually enjoy playing with their toys.</p>
              <img src='pexels-karolina-grabowska-4964285-sq-600.jpg' className='frontPage--section2--img1 img--rad' alt='img'></img>
            </div>
        </div>
        <div className='line'></div>
        <h1 className='frontPage--h1'>Get started</h1>
        <div className='frontPage--section2'>
            
              <div className='main--reasons'>
                
                <Link to='/inventory' className='icon--inCenter' >
                  <IoIosAlbums size={50} />
                </Link>
                <p className='frontPage--section2--text2'>1. Go to Inventory.</p>
                
              </div>
            
            
            <div className='main--reasons'>

            <MdAddAPhoto size={50} className='icon--inCenter'/>
              <p className='frontPage--section2--text2'>2. Add toys.</p>
            </div>
            <div className='main--reasons'>
              <Link to='/settings' className='icon--inCenter' >
                <IoIosSettings size={50} />
              </Link>
              <p className='frontPage--section2--text2'>3. Setting up the app to meet your needs.</p>
            </div>
            <div className='main--reasons'>
              <Link to='/rotation' className='icon--inCenter' >
                <IoMdSwap size={50} />
              </Link>
              <p className='frontPage--section2--text2'>4. Rotate your toys.</p>
            </div>
        </div>
        <div className='frontPage--h1'>
          
              <iframe className='img--rad' width="300" height="630" src="https://www.youtube.com/embed/PrXciUYIjKs?si=7cgk8r2iE6Z7fO9F&amp;controls=0" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
          
        </div>
        {/* <div className='line'></div> */}
        {/* <div className='frontPage--h1'>
           <h2>Thank you for joining our web app! We're thrilled to have you as part of our community.</h2>
        </div> */}

        <br/>
        </div>
        <div className='line'></div>
        <h1 className='frontPage--h1'>Main Reasons to Use Sort Toys</h1>
        <div className='frontPage--section2'>
            <div className='main--reasons'>
              <GiFallingStar size={50} className='icon--inCenter'/>
              <p className='frontPage--section2--text2'>It helps to keep track of all your toys and maintain a clean playroom.</p>
              
            </div>
            <div className='main--reasons'>
            <GiSheep size={50} className='icon--inCenter'/>
              <p className='frontPage--section2--text2'>The rotation feature enables you and your child to enjoy playing with all your toys.</p>
              
            </div>
            <div className='main--reasons'>
            <GiRhinocerosHorn size={50} className='icon--inCenter'/>
              <p className='frontPage--section2--text2'>It is easy to use, and you can access your toys anytime, anywhere.</p>
              
            </div>
        </div>
        <br/>
        {/* <div className='line'></div>
        <h1 className='frontPage--section2--text1'>About</h1>
        <div className='frontPage--section2'>
            <div className='frontPage--section2--div'>
              <p className='frontPage--section2--text2'>When kids have too many choices of toys it can make them feel overwhelmed. Having everything out at once creates clutter that leaves YOU feeling overwhelmed. The answer to both those problems is a toy rotation system.</p>
              <img src='pexels-tatiana-syrikova-3933033-sq-600.jpg' className='frontPage--section2--img1 img--rad' alt='img'></img>
            </div>
            <div className='frontPage--section2--div'>
              <p className='frontPage--section2--text2'>Inspired by Montessori philosophy, toy rotation allows you to bring out a certain number of toys and switch them out at set intervals. How many toys and how often to change them is up to you. Our purpose is to make the "how" of the process easier.</p>
              <img src='pexels-tatiana-syrikova-3932930-sq-600.jpg' className='frontPage--section2--img1 img--rad' alt='img'></img>
            </div>
            <div className='frontPage--section2--div'>
              <p className='frontPage--section2--text2'> Instead of making spreadsheets or keeping long lists of toys, store them in your digital inventory and get a new rotation in seconds. Take the clutter out of your home and have your kids actually enjoy playing with their toys.</p>
              <img src='pexels-karolina-grabowska-4964285-sq-600.jpg' className='frontPage--section2--img1 img--rad' alt='img'></img>
            </div>
        </div> */}
        
        
        <div className='footer'>
           <div className='footer--two--columns'>
              <div>
                    <h2 className='footer--text'>Follow</h2>
                    {/* <a href='https://twitter.com/sorttoys'><FaTwitter size={40} className='footer--icon'/></a> */}
                    <a href='https://www.tiktok.com/@sort.toys'><FaTiktok size={40} className='footer--icon'/></a>
                    <a href='https://www.instagram.com/sorttoys/'><AiFillInstagram size={40} className='footer--icon'/></a>
                    <a href='https://www.youtube.com/@rotatetoys'><FaYoutube size={40} className='footer--icon'/></a>
                    
                    
                    <ul className="copyright">
                      <li>&copy; Sort Toys. All rights reserved</li>
                    </ul>
              </div>
              <div className='footer--links'>
                     <span className="register-screen__subtext "><Link className="footer--link" to="/terms">Terms of use</Link></span>
                     <span className="register-screen__subtext"><Link className="footer--link" to="/privacy">Privacy policy</Link></span>
                     <span className="register-screen__subtext"><Link className="footer--link" to="/contactus">Contact us</Link></span>
              </div>
           </div>
              
        </div>
        
        {/* <p>When kids have too many choices of toys it can make them feel overwhelmed. Having everything out at once creates clutter that leaves YOU feeling overwhelmed. The answer to both those problems - a toy rotation system. Inspired by Montessori philosphy, toy rotation allows you to bring out a certain number of toys and switch them out at set intervals. How many toys and how often to change them is up to you. Our purpose is to make the "how" of the proess easier. Instead of making spreadsheets or keeping long lists of toys, just store themm in your digital inventroy and get a new rotation in seconds. Take the clutter out of your home and have your kids actually enjoy playing their toys. </p> */}
    </div>
  )
}

export default FrontPage