import React, {  useState} from 'react';
import Alert from './Alert';
import { IoCloseCircleSharp } from "react-icons/io5";
import { MdAddAPhoto } from "react-icons/md";
// import axios from "axios";
// import { CgColorBucket } from 'react-icons/cg';
import Resizer from "react-image-file-resizer";

export default function Upload(props) {
    const [fileInputState, setFileInputState] = useState('');
    const [formData, setFormData] = useState(
        {
            title:"",
            type:props.settings[0].toyTypes[0],
            // basket: props.settings[0].toysBaskets[0],
            caption:"",
        }
    )
    const [previewSource, setPreviewSource] = useState('');
    const [selectedFile, setSelectedFile] = useState();
    const [successMsg, setSuccessMsg] = useState('');
    const [errMsg, setErrMsg] = useState('');
    const [buttonSubmit, setButtonSubmit] = useState(true)
    const [noImage, setNoImage] = useState(false)

    const resizeFile = (file) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      640,
      1000,
      "JPEG",
      60,
      0,
      (uri) => {
        resolve(uri);
      },
      "file"
    );
  });

   
    const handleFileInputChange = async (e) => {
        try{

            // console.log(e.target.files[0])
            // console.time('resizeFile')
            const file = await resizeFile(e.target.files[0]);
            // console.timeEnd('resizeFile')
            // console.log(file)
            previewFile(file);
            setSelectedFile(file);
            setFileInputState(e.target.value);
        }catch (err) {
            console.log(err);
        }
        
    };

    function handleChange(event) {
        const {name, value, type, checked} = event.target
        setFormData(prevFormData => {
            return {
                ...prevFormData,
                [name]: type === "checkbox" ? checked : value
            }
        })
    }

    const previewFile = (file) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
            setPreviewSource(reader.result);
        };
    };

    const handleSubmitFile = (e) => {
        e.preventDefault();
        
        
        if (!selectedFile){
            setNoImage(!noImage)
            return};
        const reader = new FileReader();
        reader.readAsDataURL(selectedFile);
        reader.onloadend = () => {
            // console.log(`toys length ${props.toys.length}`)
            // console.log(`toys limit ${props.settings[0].toysLimit}`)
            if(props.toys.length < props.settings[0].toysLimit){
                uploadImage(reader.result, formData.title, formData.type, formData.caption);
            }else{
                buttonSubmitUpdate()
                // setErrMsg('toys amount reached the limit');
                return
            }
            props.setIcon(false)
            props.setIcon(true)
        };
        reader.onerror = () => {
            console.error('AHHHHHHHH!!');
            setErrMsg('something went wrong!');
        };
    };

    const uploadImage = async (base64EncodedImage, title, type, caption) => {
        // console.log(base64EncodedImage)
        try {
            // console.log(base64EncodedImage)
            await fetch('/api/private/addToy', {
                credentials: 'include',
                method: 'POST',
                body: JSON.stringify({ data: base64EncodedImage, title: title, type: type, caption: caption }),
                headers: { 'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            },
                
            });
            

            // console.log({ data: base64EncodedImage, firstName: firstName })
            setFileInputState('');
            setPreviewSource('');
            setSuccessMsg('Image uploaded successfully');
            props.setNewToy(!props.newToy)
            
        } catch (err) {
            console.error(err);
            setErrMsg('Something went wrong! Try again later!');
        }     
    };
    const hiddenFileInput = React.useRef(null);//photo icon
    const handleIcon = event => {//photo icon
        hiddenFileInput.current.click();
    };

    const hideUpload = () => {
        props.setIcon(!props.icon)
    }



    
    const printSelect =(array) => array.map(item =>  {
        return (
                <option key={item} value={item}>{item}</option>
        )
    })

  

    // const click = () =>{
    //     setUpdate(!update)
    // }
    const buttonSubmitUpdate = () => {
        setButtonSubmit(!buttonSubmit)
    }


    return (
        <div className='upload'>
            {/* <h1 className="title">Upload an Image</h1> */}


            
            <form onSubmit={handleSubmitFile} className="inventory-screen__form" >
            <IoCloseCircleSharp  size={40} onClick={hideUpload} color="#363951"/>
               <div className="form-group">
                    <div className='input--image--icon'>
                        <MdAddAPhoto //photo icon
                            size={70}
                            cursor="pointer"
                            color="#363951"
                            onClick={handleIcon}
                        />
                    </div>

                    <input
                    
                        ref={hiddenFileInput}//photo icon
                        id="fileInput"
                        type="file"
                        name="image"
                        onChange={handleFileInputChange}
                        value={fileInputState}
                        className="form-input input--image border--radius"//photo icon
                        
                        
                    />
                    
                    
                    <input
                        type="text"
                        placeholder="Title"
                        onChange={handleChange}
                        name="title"
                        value={formData.title}
                        className="border--radius"
                        required
                    />
                        {/* <input
                        type="text"
                        placeholder="type"
                        onChange={handleChange}
                        name="type"
                        value={formData.type}
                        className="border--radius"
                    /> */}
                        <select type="text" name="type" value={formData.type} onChange={handleChange} className="border--radius">
                            {printSelect(props.settings[0].toyTypes)}
                        </select>
                        {/* <select type="text" name="basket" value={formData.basket} onChange={handleChange} className="border--radius">
                            {printSelect(props.settings[0].toysBaskets)}
                        </select> */}

                        <textarea 
                        type="text"
                        placeholder="Notes"
                        onChange={handleChange}
                        name="caption"
                        value={formData.caption}
                        className="border--radius"
                    />
                    {<Alert msg={errMsg} type="danger" />}
                    {<Alert msg={successMsg} type="success"/>}
                    {(noImage) && <p style={{color:'red'}}>Image was not picked</p>}
                    {(!buttonSubmit) && <p style={{color:'red'}}>Toys amount reached the limit</p>}
                    <button className="btn btn-primary border--radius"  type="submit">
                    Submit
                    </button>
                    
                     {/* <img className='submitButtonInfinity' src='Infinity-2.9s-61px.svg' alt='Infinity-2.9s-61px.svg'></img> */}
                    
                    
                    
                    {previewSource && (
                        <img
                            src={previewSource}
                            alt="chosen"
                            className='previewImageUpload border--radius'
                            style={{ width: '100%' }}
                        />
                     )}
                   
               </div> 
            </form>

        </div>
    );
}
