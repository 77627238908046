import React, { useState, useEffect } from 'react'
import "./InventoryTips.css"
import { IoCloseCircleSharp } from "react-icons/io5";

function InventoryTips(props) {

    const [tip, setTip] = useState('first')
    const {setShowTips,showTips} = props
    useEffect(() => {
        const tipsWereShown = localStorage.getItem("inventoryTips")
        if(tipsWereShown === 'true'){
            setShowTips(false)
        }
    }, [setShowTips]);

    const clickNext = () => {
        if(tip === 'first'){
            setTip('second')
        }else if(tip === 'second'){
            setTip('third')
        }else if(tip === 'third'){
            
        }
    }
    const clickBack = () => {
        if(tip === 'third'){
            setTip('second')
        }else if(tip === 'second'){
            setTip('first')
        }else if(tip === 'first'){
            
        }
    }
    const closeTipsWindow = () => {
        setShowTips(!showTips)
        localStorage.setItem("inventoryTips", true);
    }

  return (
    <div className=''>
       <IoCloseCircleSharp
            className='closeIcon'
            size={40}
            // cursor="pointer"
            // color="#363951"
            onClick={closeTipsWindow}
        />
        <div className='tips--title'>tips:</div>
        {tip === "first" &&
        <div className='tips--main'>
            <div className='tips--tip'>First, add your toys.</div>
            <div className='tips--progress'>
                <div className='tips--progress--1'>33%</div>
            </div>
            <div className='tips--buttons'>
                <div className='tips--button' onClick={clickBack}>Back</div>
                <div className='tips--button' onClick={clickNext}>Next</div>
            </div>
        </div>}
        {tip === "second" &&
        <div className='tips--main'>
            <div className='tips--tip'>Go to the <b>Settings tab</b> to choose how many toys you would like to rotate.</div>
            <div className='tips--progress'>
                <div className='tips--progress--2'>67%</div>
            </div>
            <div className='tips--buttons'>
                <div className='tips--button' onClick={clickBack}>Back</div>
                <div className='tips--button' onClick={clickNext}>Next</div>
            </div>
        </div>}
        {tip === "third" &&
        <div className='tips--main'>
            <div className='tips--tip'>Go to the <b>Rotation tab</b> to make a toy rotation.</div>
            <div className='tips--progress'>
                <div className='tips--progress--3'>100%</div>
            </div>
            <div className='tips--buttons'>
                <div className='tips--button' onClick={clickBack}>Back</div>
                <div className='tips--button' onClick={clickNext}>Next</div>
            </div>
        </div>}
    </div>
  )
}

export default InventoryTips