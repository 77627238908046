import React, {  useState} from 'react';
// import Popup from './Popup'

export default function DeletionForm(props) {
    
    // const [disableButton, setDisableButton] = useState(true)
    // const [msg, setMsg] = useState(null)
    // const [show, setShow] = useState(false);
    const [formData, setFormData] = useState(
        {email: "",subject:"Deletion Form", message:"", deleteAccount: false}
    )

    function handleChange(event){
        const {name, value, type, checked} = event.target
        setFormData(prevFormData =>  {
            return {
                ...prevFormData,
                [name]: type === "checkbox" ? checked : value
                // [event.target.name]: event.target.value
            }
        })
    }


    const submitMessage = async (formData) => {
        try {
            if(formData.email.trim() === '' || !formData.email.includes('@')){
                // setMsg('Email required!')
                return
            }
            if(formData.deleteAccount === false){
                // setMsg('Click checkbox!')
                return
            }

            await fetch('/api/public/deletionform', {
                // credentials: 'include',
                method: 'POST',
                body: JSON.stringify(formData),
                headers: { 'Content-Type': 'application/json',
                // Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            },
            });
            // const data = await response.json()
            // setMsg(data)
            // console.log({ msg: 'Something went wrong! Try again later!' }.json())
            
        } catch (err) {
            // setMsg({ msg: 'Something went wrong! Try again later!' })
            console.error(err);
        }     
    };
    const submitButton = () =>{
        submitMessage(formData)
        // setDisableButton(true)
    }
    
    // useEffect(() => {
    //     if (msg) {
    //         setShow(true);
    //         setInterval(() => {
    //             setShow(false);
    //         }, 5000);
    //     }
    // }, [msg]);

    return (
        <div className='upload'>
            <h1 className="terms--header">Deletion Request</h1>
            <form  className="inventory-screen__form" >
               <div className="form-group">
                    {/* {show && 
                        <div className=" popup--window">
                            <div style={{color:"red"}}className='popup--message'>🦆 {msg.msg} 🦆</div>
                        </div>
                    } */}
                    <input
                        type="email"
                        placeholder="Your email"
                        onChange={handleChange}
                        name="email"
                        value={formData.email}
                        className="border--radius"
                        maxLength="500"
                        required={true}
                    />
                        <textarea 
                        style={{height: '200px'}}
                        type="text"
                        placeholder="Your message"
                        onChange={handleChange}
                        name="message"
                        value={formData.message}
                        className="border--radius"
                        maxLength="1000"
                        
                    /> 
               </div> 
               <div className='deletionForm--checkbox--container'>
                    <input 
                        type="checkbox" 
                        id="deleteAccount" 
                        className='deletionForm--checkbox'
                        checked={formData.deleteAccount}
                        onChange={handleChange}
                        name="deleteAccount"
                        required={true}
                    />
                    <h4 className='deletionForm--checkbox--label'>Delete account and associated data</h4>
                </div>
                <p>Our support team will contact you as soon as possible regarding clarification on the deletion of your account, once you submit the deletion request.</p>
                <button onClick={submitButton} className="btn btn-primary border--radius"  type="submit" >
                    Submit
                </button>
            </form>

        </div>
    );
}
