import React from 'react'
import { useEffect, useState } from 'react'


function DeleteAccount(props) {

    const [show, setShow] = useState(false)
    const [checked, setChecked] = useState(false);
    const [resp, setResp] = useState(null)
    const [showResp, setShowResp] = useState(false);

    const handleChange = () => {
        setChecked(!checked);
    };
    const showWindow = () =>{
        setShow(true)
    }
   
    const deleteMyAccount = async () =>{
        console.log('delete my account')
        try {
            const response = await fetch(`/api/private/deleteUser/`, {
                credentials: 'include',
                method: 'POST',
                // body: JSON.stringify(),
                headers: { 'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            },
                
            });
            
            const data = await response.json()
           
            await setResp(data.msg)
          
        } catch (err) {
            console.error(err);
            // setErrMsg('Something went wrong!');
        }
    }
   
    useEffect(() => {
        if (resp) {
            setShowResp(true);
            setInterval(() => {
                setShowResp(false);
            }, 5000);
        }
    }, [resp]);

  
return (
    <div className='settings--comp'>
        <div className="inventory-screen__form">
            {show ? 
            (<div>
                <label>
                    <input type="checkbox" checked={checked} onChange={handleChange}  className='delete--account--checkbox'/>
                    I understand that the deletion of my account is irreversible and will result in the termination of services with Sort Toys.
                </label>
                {showResp && <div className='popup--message' style={{color:'red'}}>{resp}</div>}
                <div className="form-group">
                    <button className="btn btn-primary border--radius" onClick={deleteMyAccount} disabled={!checked}>
                        ok
                    </button>
                </div>
            </div>):

            (<div>
                <p className='title--block'>Would you like to delete your account?</p>
                <div className="form-group">
                    <button className="btn btn-primary border--radius" onClick={showWindow} >
                        Delete
                    </button>
                </div> 
            </div>
            )}
        </div>
    </div>
  )
}

export default DeleteAccount