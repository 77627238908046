import React from 'react'
import ContactUsForm from './ContactUsForm'


function ContactUsScreen() {
  return (
    <div className='main'>
        
        <h1 className='terms--header'>Contact Us</h1>
        <ContactUsForm></ContactUsForm>
        
    </div>
  )
}

export default ContactUsScreen