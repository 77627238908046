import * as IoIcons from 'react-icons/io';
import React, {  useState } from 'react';
import CardItemInABasket from './CardItemInABasket';

function ListOfToysInABasket(props) {
    const [addWindow,setAddWindow] = useState(false)
    //remove from basket states
    const [listOfToysABasket,setListOfToysABasket] = useState(props.toys.filter(element => element.basket.includes(props.item)))
    const [listToDelete, setListToDelete] = useState([])
    //add to the basket states
    const [listOfToysOutOfTheBasket,setListOfToysOutOfTheBasket] = useState(props.toys.filter(element => element.basket !== props.item))
    const [listToAdd, setListToAdd] = useState([])
    const [search, setSearch] = useState('')

    const openBasket = () => {
        props.setShowBasket(!props.showBasket)
    }

    const printListOfToysInABasket = props.toys.filter(element => element.basket.includes(props.item)).map((element, index) =>{
        return(
            <CardItemInABasket
                item={element}
                key={index}
                listOfToysABasket={listOfToysABasket}
                setListOfToysABasket={setListOfToysABasket}
                listToDelete={listToDelete}
                setListToDelete={setListToDelete}
                triger={props.triger}
                setTriger={props.setTriger}
            />
        )
    })

    // if(search){
    //     searchResults = sortedArray.filter(el =>{
    //         let searchLowerCase = search.toString().toLowerCase()
    //         let titleLowerCase = (el.title).toString().toLowerCase()
    //         return titleLowerCase.includes(searchLowerCase)
    //     })
    // }else{
    //     searchResults = sortedArray
    // }
    const printListOfToysOutOfTheBasket = props.toys
        .filter(element => element.basket.includes(props.item) === false)
        .filter(el =>{
            let searchLowerCase = search.toString().toLowerCase()
            let titleLowerCase = (el.title).toString().toLowerCase()
            return titleLowerCase.includes(searchLowerCase)
        })
        .map((element) =>{
            return(
                <CardItemInABasket
                    item={element}
                    key={element._id}
                    listOfToysABasket={listOfToysOutOfTheBasket}
                    setListOfToysABasket={setListOfToysOutOfTheBasket}
                    listToDelete={listToAdd}
                    setListToDelete={setListToAdd}
                />
            )
        })

        // useEffect(() => {
        //     console.log(listToAdd)
        // }, [listToAdd]);

    const openAddWindow = async() => {
        // console.log('Add Window')
        setAddWindow(!addWindow)
        setListToAdd([])
        setSearch('')
    }
    const removeItemsFromBasket = async () => {
        try{
            // console.log('delet listToDelete')
            let ids = []
            listToDelete.forEach(element => ids.push(element._id))
            // console.log(ids)
            //set value basket to Sotorage for all elements form listToDelete
            //send array to server
            if(ids.length === 0){return}
            await fetch(`/api/private/updateBasket/`, {
                credentials: 'include',
                method: 'PUT',
                body: JSON.stringify({ ids: ids, basket: props.item }),
                headers: { 'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            },
                
            });
            setListToDelete([])
            await props.setTriger(!props.triger)
        } catch (err) {
            console.error(err);
            // setErrMsg('Something went wrong!');
        }
    }
    const addItemsToTheBasket = async () => {
        try{
            // console.log('addItemsToTheBasket')
            // console.log(props.item)
            let ids = []
            listToAdd.forEach(element => ids.push(element._id))
            // console.log(ids)
            //set value basket to Sotorage for all elements form listToDelete
            //send array to server
            if(ids.length === 0){return}
            await fetch(`/api/private/updateBasket/`, {
                credentials: 'include',
                method: 'PUT',
                body: JSON.stringify({ ids: ids, basket: props.item }),
                headers: { 'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            },
                
            });
            await props.setTriger(!props.triger)
            setSearch('')
        } catch (err) {
            console.error(err);
            // setErrMsg('Something went wrong!');
        }
    }
    // useEffect(() => {
    //     console.log(search)
    // }, [search]);

    function handleChange(event) {
        const {value} = event.target
        setSearch(value)
        
    }

  return (
    <div >
        <div className='listOfToysInABasketHeader'>
            <div  onClick={openBasket}><IoIcons.IoMdArrowBack className='iconInABasketBack' size={50}/></div>
            {/* search input */}
            <div className='basketNameInAList'>{props.item}</div>
        </div>
        {(listOfToysABasket.length === 0) && <div className='basketsListComment'><span >Click the '+' icon to add toys. If you have nothing in the Add Window, first add toys to the inventory. To remove toys from the collection, select them from the list and click the '-' icon.</span></div>}
        <div className='listOfToysInABasket ' >
            {printListOfToysInABasket}
        </div>
        <div className='listOfToysInABasketFooter'>
            <div  onClick={removeItemsFromBasket}><IoIcons.IoIosRemove className='iconInABasketBottom' size={50}/></div>
            <div  onClick={openAddWindow}><IoIcons.IoIosAdd className='iconInABasketBottom' size={50}/></div>
        </div>
        {addWindow && 
        <div className='basketAddWindow'>
            <div className='basketAddWindowHeader'>
                <div  onClick={openAddWindow}><IoIcons.IoMdArrowBack className='iconInABasketBack' size={50}/></div>
                <div className='basketAddWindowHeaderBasketName'>Add to {props.item}</div>
            </div> 
                <input
                     type="text"
                     placeholder="Search"
                     className="basketAddWindowSearch"
                     onChange={handleChange}
                     name="serach"
                     value={search}
                />
            <div className='basketAddWindowList'>
                {printListOfToysOutOfTheBasket}
            </div>
            <button className='btn btn-primary card4--btn basketAddWindowButton' onClick={()=> {addItemsToTheBasket();openAddWindow()}}>Add to Collection({listToAdd.length})</button>
        </div>}
    </div>
    
  )
}

export default ListOfToysInABasket