import React from 'react'
import { AiOutlineDelete, AiOutlineEdit } from "react-icons/ai";
import { useState} from 'react';

function Category(props) {
    const [edit, setEdit] = useState(false)
    const [formData, setFormData] = useState(
        {
            toyType:props.settings[0].toyTypes[props.index],
        }
    )

    const editCategory = () =>{
        
        setEdit(!edit)
    }

    const deleteToyType = async (element) => {
        try {
            // console.log('delete picked type')
            // let elementIndex = props.settings[0].toyTypes.indexOf(element)
            
            // let arr = props.settings[0].toyTypes
            // console.log(arr)
            // arr.splice(elementIndex, 1)
            props.setUpdate(!props.update)
            // console.log('delete item  and update state')
            await fetch(`/api/private/deleteType/`, {
                credentials: 'include',
                method: 'PUT',
                body: JSON.stringify({typeToDelete:element }),
                headers: { 'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            },
                
            });
            props.setParam(!props.param)
        } catch (err) {
            console.error(err);
            // setErrMsg('Something went wrong!');
        }
    };
    const eidtToyType = async (newTypeName,index,toyTypes) => {
        //input {newTypeName:string, index: number, toyTypes: array}
        try {
            // props.setUpdate(!props.update)

            await fetch(`/api/private/updateTypeName/`, {
                credentials: 'include',
                method: 'PUT',
                body: JSON.stringify({newTypeName:newTypeName, index:index, toyTypes:toyTypes}),
                headers: { 'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            },
                
            });
            props.setParam(!props.param)
        } catch (err) {
            console.error(err);
            // setErrMsg('Something went wrong!');
        }
    };

    function handleChange(event) {
        const {name, value, type, checked} = event.target
        setFormData(prevFormData => {
            return {
                ...prevFormData,
                [name]: type === "checkbox" ? checked : value
            }
        })
    }
    const handleSubmitFile = (e) => {
        e.preventDefault();
        
        // let array = props.settings[0].toyTypes
        // array.splice(props.index, 1, formData.toyType)

        // props.updateToyTypes(array);
        eidtToyType(formData.toyType,props.index,props.settings[0].toyTypes)
        setEdit(!edit)

    };  



  return (
    <div>
            {(!edit) ?
            <div className='flex--row toyTypeRow' key={props.item} >
                    <div >{props.item} </div>
                    {(props.item !== "Uncategorized") ?
                    <div >
                        <AiOutlineEdit className='pointer ' size={30} onClick={() => {editCategory();}}/>
                        <AiOutlineDelete className='pointer ' size={30} onClick={() => {deleteToyType(props.item);}}/>
                    </div> :
                    <div></div> }
            </div>:
            <div className='flex--row toyTypeRow' key={props.item} >
                <form   >
                    <input
                    type="text"
                    placeholder="add your type"
                    className="border--radius"
                    
                    
                    onChange={handleChange}
                    name="toyType"
                    value={formData.toyType}
                    />
                    
                        <button className="btn btn-primary border--radius" onClick={handleSubmitFile} type='submit'>Submit</button>
                        <button className="btn btn-primary border--radius" onClick={editCategory}>Cancel</button>
                    
                </form>
            </div>
            }
    </div>
  )
}

export default Category