import React from 'react'

import Pricing from './Pricing';

function FrontPage() {
  return (
    <div className='main'>
        
        <Pricing/>    
        
        
    </div>
  )
}

export default FrontPage