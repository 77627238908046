
import React from 'react';
// import { Link } from 'react-router-dom';

function PricingNoAuth() {
  return (
    <div>
        <h1 className='frontPage--section2--text1'>Pricing</h1>
        <div className='pricingPlans '>
            <div className={`corner-ribbon pricingCard frontPage--paimentSection--div img--rad `}>
                <div >
                    <h2 className='frontPage--section2--text2'>Free</h2>
                    <h1 className='frontPage--section2--text2'>$0/month</h1>
                    <br/>
                    <h3 className='frontPage--section2--text2'>- up to 100 toys</h3>
                    <h3 className='frontPage--section2--text2'>- up to 3 collections</h3>
                    <br/>
                </div>
            </div>
            
            <div className={`corner-ribbon pricingCard frontPage--paimentSection--div img--rad `}>
                <div >
                    <h2 className='frontPage--section2--text2'>Premium</h2>
                    <h1 className='frontPage--section2--text2'>$6/month</h1>
                    <br/>
                    <h3 className='frontPage--section2--text2'>- up to 500 toys</h3>
                    <h3 className='frontPage--section2--text2'>- up to 50 collections</h3>
                    {/* <h3 className='frontPage--section2--text2'>- email reminder</h3> */}
                    <br/>
                </div>
            </div>
        </div>
    </div>
  )
}

export default PricingNoAuth