import React from 'react'
import { AiOutlineDelete, AiOutlineEdit } from "react-icons/ai";
import { useState} from 'react';

function CategoryBasket(props) {
    const [edit, setEdit] = useState(false)
    const [formData, setFormData] = useState(
        {
            toysBaskets:props.settings[0].toysBaskets[props.index],
        }
    )

    const editCategory = () =>{
        
        setEdit(!edit)
    }

    const deleteToyBasket = async (basketToDelete) => {
        try {
            let elementIndex = props.settings[0].toysBaskets.indexOf(basketToDelete)
            let arr = props.settings[0].toysBaskets
            arr.splice(elementIndex, 1)
            props.setUpdate(!props.update)
            await fetch(`/api/private/deleteBasket/`, {
                credentials: 'include',
                method: 'PUT',
                body: JSON.stringify({ basketToDelete: basketToDelete, newToysBaskets:arr }),
                headers: { 'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            },
                
            });
            
        } catch (err) {
            console.error(err);
            // setErrMsg('Something went wrong!');
        }
    };

    function handleChange(event) {
        const {name, value, type, checked} = event.target
        setFormData(prevFormData => {
            return {
                ...prevFormData,
                [name]: type === "checkbox" ? checked : value
            }
        })
        // console.log(formData)
    }
    const handleSubmitFile = (e) => {
        e.preventDefault();
        
        let array = props.settings[0].toysBaskets
        // if(array.includes(formData.toysBasketss) || formData.toysBasketss.trim() === ''){return}
        // let typesArray = array.concat(formData.toysBasketss)
        array.splice(props.index, 1, formData.toysBaskets)
        console.log(array)
        props.editToysBaskets(array);
        setEdit(!edit)
        // props.setParam(!props.param)
        // props.setUpdate(!props.update)
        // console.log('handle submit type')
    };  

  return (
    <div>
            {(!edit) ?
            <div className='flex--row toyTypeRow' key={props.item} >
                    <div >{props.item} </div>
                    
                    <div >
                        <AiOutlineEdit className='pointer ' size={30} onClick={() => {editCategory();}}/>
                        <AiOutlineDelete className='pointer ' size={30} onClick={() => {deleteToyBasket(props.item);}}/>
                    </div> 
            </div>:
            <div className='flex--row toyTypeRow' key={props.item} >
                <form   >
                    <input
                    type="text"
                    placeholder="add your type"
                    className="border--radius"
                    
                    
                    onChange={handleChange}
                    name="toysBaskets"
                    value={formData.toysBaskets}
                    />
                    
                        <button className="btn btn-primary border--radius" onClick={handleSubmitFile} type='submit'>Submit</button>
                        <button className="btn btn-primary border--radius" onClick={editCategory}>Cancel</button>
                    
                </form>
            </div>
            }
    </div>
  )
}

export default CategoryBasket