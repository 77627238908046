import React, { useEffect, useState } from 'react';
// import { Image } from 'cloudinary-react';
import axios from "axios";
import RotationToysNumber from './RotationToysNumber';
import ListOfToyTypes from './ListOfToyTypes';
import ListOfToysBaskets from './ListOfToysBaskets'
// import LinkForBillingPortal from './LinkForBillingPortal';
// import UsernameEmail from './UsernameEmail';
import DeleteAccount from './DeleteAccount'
// import RemindToRotate from './RemindToRotate';

export default function Home() {
    const [settings, setSettings] = useState([{"toysLimit": 1, "rotationToysNumber": 'Log in, please', "id": 1, "toyTypes": [1], "toysBaskets":[1]}]);
    const [toys, setToys] = useState([1]);
    const [param, setParam] = useState();
    const [userStatus, setUserStatus] = useState('')
 
    const [excludedToysAmount, setexcludedToysAmount] = useState(1);
    

    const loadSettings = async () => {
        try {
            const config = {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${localStorage.getItem("authToken")}`,
                },
              };
            let {data} = await axios.get('/api/private/getsettings', config);
            
            
            setSettings(data)  
            
        } catch (err) {
            console.error(err);
        }
    };
    const  loadToys = async () => {
        try {
            const config = {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${localStorage.getItem("authToken")}`,
                },
              };
            let {data} = await axios.get('/api/private/getUserToys', config);
            
            
            setToys(data)  
            // console.log(settings)
        } catch (err) {
            console.error(err);
        }
    };

    const loadFavarites = async () => {
        try {
            const config = {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${localStorage.getItem("authToken")}`,
                },
              };
            let {data} = await axios.get('/api/private/excludedToysAmount', config);
            
            
            setexcludedToysAmount(data)  
           
        } catch (err) {
            console.error(err);
        }
    };

    const loadUserStatus = async () => {
        try {
            const config = {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${localStorage.getItem("authToken")}`,
                },
              };
            let {data} = await axios.get('/api/stripe/get_product_status', config);
            setUserStatus(data.status)  
        } catch (err) {
            console.error(err);
        }
    };


    useEffect(() => {
        loadSettings();
        loadToys();
        loadFavarites()
        loadUserStatus()
    }, [param]);




    return (
        <div>
            
            <div className="gallery">
                <h1 className="title">Settings</h1>
                <RotationToysNumber  
                    settings={settings}
                    toys={toys}
                    setParam={setParam}
                    param={param}
                    favarites={excludedToysAmount}
                />
                <ListOfToyTypes 
                    settings={settings}
                    setParam={setParam}
                    param={param}
                    
                />
                <ListOfToysBaskets
                    settings={settings}
                    setParam={setParam}
                    param={param}
                    userStatus={userStatus}
                    
                />
                {/* {(userStatus === 'paid') &&
                <RemindToRotate
                    settings={settings}
                />
                } */}
                
                {/* <UsernameEmail
                settings={settings}
                toys={toys}
                setParam={setParam}
                param={param}
                favarites={excludedToysAmount}
                 /> */}
                 <DeleteAccount />
                
            </div>
                

        </div>
    );
}
