import React from "react";
import "./Card.css"
// import * as CgIcons from 'react-icons/cg';
import { Image } from 'cloudinary-react';

export default function Card(props){
    // const deleteToy = async () => {
    //     try {
    //         await fetch(`/api/private/deletetoy/${props.item._id}`, {
    //             credentials: 'include',
    //             method: 'DELETE',
    //             // body: JSON.stringify({ rotationToysNumber: formData.rotationToysNumber }),
    //             headers: { 'Content-Type': 'application/json',
    //             Authorization: `Bearer ${localStorage.getItem("authToken")}`,
    //         },
                
    //         });
    //         props.setNewToy(!props.newToy)
    //     } catch (err) {
    //         console.error(err);
    //     }
    // }
    const showId =  () =>  {
        
        props.setOpenCard(!props.openCard)
        props.setOpenCardObj(props.item)
    }
    

    return(
        <div className="card" onClick={showId}>
            
            <Image className="card--img"
                // key={index}
                cloudName={process.env.REACT_APP_CLOUDINARY_NAME}
                publicId={props.item.cloudinaryId}
                width="136"
                height="136"
                crop="fill"
                
                
            />
            
            <div className="card--info">
                {/* <div className="card--align-right">
                    <div></div>
                    <div onClick={deleteToy}><CgIcons.CgCloseO size={32} /></div>
                </div> */}

                {/* <div className="row">
                    <p className="card--location">{props.item.location}</p>
                    <a className="card--google" href={props.item.googleMapsUrl}>View on Google Maps</a>
                </div> */}
                <h1 className="card--title">{props.item.title}</h1>
                
                {/* <h5 className="card--date">{props.item.startDate} - {props.item.endDate}</h5> */}
                
                <p className="card--description">{props.item.type}</p>
                {(props.item.basket) && <p className="card--description">{props.item.basket.join(' ')}</p>}
                {(props.item.excludedToy) && <p className="card--description" style={{color: 'green'}}>Excluded from rotation</p>}
                {(props.item.favoriteToy) && <p className="card--description" style={{color: 'green'}}>Keep in rotation</p>}
            </div>
        </div>
    )
}