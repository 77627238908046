import React from 'react'
// import Alert from './Alert';
import { useState, useEffect } from 'react';
import CategoryBasket from './CategoryBasket';

function ListOfToyTypes(props) {
    const  [update, setUpdate] = useState(true)
    const [formData, setFormData] = useState(
        {
            toysBaskets:"",
        }
    )
    const [errMsg,setErrMsg] = useState(false)
    
    const printToysBaskets =() => props.settings[0].toysBaskets.map((item, index) =>  {
        
        return (
            <CategoryBasket 
                key={index}
                index={index}
                item={item}
                settings={props.settings}
                update={update}
                setUpdate={setUpdate}
                updateToysBaskets={updateToysBaskets}
                param={props.param}
                setParam={props.setParam}
                editToysBaskets={editToysBaskets}
            />
        )
    })

    useEffect(() => {
        // printToysBaskets()
    }, [update]);

 

    function handleChange(event) {
        const {name, value, type, checked} = event.target
        setFormData(prevFormData => {
            return {
                ...prevFormData,
                [name]: type === "checkbox" ? checked : value
            }
        })
    }
    const handleSubmitFile = (e) => {
        e.preventDefault();
        
        let array = props.settings[0].toysBaskets
        if(array.includes(formData.toysBaskets) || formData.toysBaskets.trim() === '' ) {return}
        let typesArray = array.concat(formData.toysBaskets)
        
        updateToysBaskets(typesArray);
        props.setParam(!props.param)
        // setUpdate(!update)
        // console.log('handle submit type')
        setFormData({
            toysBaskets:"",
        })
    };  

    const updateToysBaskets = async (arr) => {
        
        try {
            if(props.userStatus !== 'paid'){
                if(props.settings[0].toysBaskets.length>2){
                    //Alert "Free account can have up to 3 collections"
                    setErrMsg(true)
                    return
                }
            }else if(props.userStatus === 'paid'){
                if(props.settings[0].toysBaskets.length>50){
                    //Alert "Premium account can handle up to 50 collections"
                    return
                }
            }
            setUpdate(!update)
            await fetch(`/api/private/updateSettings/`, {
                credentials: 'include',
                method: 'PUT',
                body: JSON.stringify({ toysBaskets: arr }),
                headers: { 'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            },
                
            });
            // props.setParam(!props.param)
            // setUpdate(!update)
        } catch (err) {
            console.error(err);
            // setErrMsg('Something went wrong!');
        }
    };
    const editToysBaskets = async (arr) => {
        try {
            setUpdate(!update)
            await fetch(`/api/private/updateBasketName/`, {
                credentials: 'include',
                method: 'PUT',
                body: JSON.stringify({ toysBaskets: arr }),
                headers: { 'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            },
                
            });
            // props.setParam(!props.param)
            // setUpdate(!update)
        } catch (err) {
            console.error(err);
            // setErrMsg('Something went wrong!');
        }
    };
    
  return (
    <div className='settings--comp'>
        
   
        <form   className="inventory-screen__form">
            <p className='title--block'>Toy collections</p>
            <div className="form-group">
                
        
                {printToysBaskets()}
                <input
                    type="text"
                    placeholder="add new collection"
                    className="border--radius toyTypeInput"
                    
                    
                    onChange={handleChange}
                    name="toysBaskets"
                    value={formData.toysBaskets}
                />
                {errMsg && <p>Free account can have up to 3 collections.</p>}

                <button className="btn btn-primary border--radius" onClick={handleSubmitFile} type="submit">
                    Submit
                </button>
            </div>
        </form>
    </div>

  )
}

export default ListOfToyTypes