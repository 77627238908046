import React from 'react'
import { FaYoutube, FaTiktok } from "react-icons/fa";
import {AiFillInstagram} from "react-icons/ai"

import { Link } from "react-router-dom";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";

function PaymentSuccess() {
  return (
    <div className='main'>
        <div className='frontPage--section1'>
            <div className='frontPage--section1--div'>
                  <img src='logo-no-background.svg' className='frontPage--section1--logo' alt='img'></img>
                  <h3 className='frontPage--section1--text1'>Toy Rotation Made Simple</h3>
            </div>
            <div className=' img--rad successPayment--box'>
                <IoMdCheckmarkCircleOutline size={50} color='#4BB543'/>

                <h3 className='payment--text'>Payment successful</h3>
                <h3 className='payment--text'>Restart your app for correct functionality</h3>
            </div>
            <br/>
            {/* <img src='pexels-cottonbro-studio-3661222-sq-600.jpg' className='frontPage--section1--img1 img--rad' alt='img'></img> */}
            
        </div>
        
        
        <div className='footer'>
           <div className='footer--two--columns'>
              <div>
                    <h2 className='footer--text'>Follow</h2>
                    {/* <a href='https://twitter.com/sorttoys'><FaTwitter size={40} className='footer--icon'/></a> */}
                    <a href='https://www.tiktok.com/@sort.toys'><FaTiktok size={40} className='footer--icon'/></a>
                    <a href='https://www.instagram.com/sorttoys/'><AiFillInstagram size={40} className='footer--icon'/></a>
                    <a href='https://www.youtube.com/@rotatetoys'><FaYoutube size={40} className='footer--icon'/></a>
                    
                    
                    <ul className="copyright">
                      <li>&copy; Sort Toys. All rights reserved</li>
                    </ul>
              </div>
              <div className='footer--links'>
                     <span className="register-screen__subtext "><Link className="footer--link" to="/terms">Terms of use</Link></span>
                     <span className="register-screen__subtext"><Link className="footer--link" to="/privacy">Privacy policy</Link></span>
                     <span className="register-screen__subtext"><Link className="footer--link" to="/contactus">Contact us</Link></span>
              </div>
           </div>
              
        </div>
        
        {/* <p>When kids have too many choices of toys it can make them feel overwhelmed. Having everything out at once creates clutter that leaves YOU feeling overwhelmed. The answer to both those problems - a toy rotation system. Inspired by Montessori philosphy, toy rotation allows you to bring out a certain number of toys and switch them out at set intervals. How many toys and how often to change them is up to you. Our purpose is to make the "how" of the proess easier. Instead of making spreadsheets or keeping long lists of toys, just store themm in your digital inventroy and get a new rotation in seconds. Take the clutter out of your home and have your kids actually enjoy playing their toys. </p> */}
    </div>
  )
}

export default PaymentSuccess