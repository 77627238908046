
import React, { useEffect, useState } from 'react';
// import { Link } from 'react-router-dom';

function Pricing() {
    const [productStatus, setProductStatus] = useState(null)

    const getProductStatus = async () => {
        try {
            const response = await fetch('/api/stripe/get_product_status', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${localStorage.getItem("authToken")}`,
                }
            });
            const product = await response.json();
            // console.log(product)
            setProductStatus(product.status)
        } catch (err) { 
        }
    }

    useEffect(() => {
        getProductStatus()
        
    }, []);

    const buttonLink = (endPoint) => {
        fetch(endPoint, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            },
        })
            .then((response) => response.json())
            
            .then((response) => {
                // window.open(response.url, '_blank');
                window.location.replace(response.url);
            });
    }
    
  return (
    <div>
        <h1 className='frontPage--section2--text1'>Pricing</h1>
        <div className='pricingPlans'>

        
            <div className={`corner-ribbon pricingCard frontPage--paimentSection--div img--rad `}>
                <div >
                    <h2 className='frontPage--section2--text2'>Free</h2>
                    <h1 className='frontPage--section2--text2'>$0/month</h1>
                    <br/>
                    <h3 className='frontPage--section2--text2'>- up to 100 toys</h3>
                    <h3 className='frontPage--section2--text2'>- up to 3 collections</h3>
                    <br/>
                    {/* {(productStatus !== null && productStatus !== "paid") && (<button className='btn-primary btn border--radius' onClick={ () => buttonLink('api/stripe/billing_portal') }>Billing portal</button>)} */}
                    {/* {productStatus !== "paid" && (productStatus !== null ? <button className='btn-primary btn border--radius' onClick={ () => buttonLink('api/stripe/billing_portal') }>Upgrade</button> : <button className='btn-primary btn border--radius' onClick={ () => buttonLink('/api/stripe/create_checkout_link_premium') }>Upgrade</button>)} */}
                    {productStatus !== "paid" && (<button className='btn-primary btn border--radius' onClick={ () => buttonLink('/api/stripe/create_checkout_link_premium') }>Upgrade</button>)}
                </div>
                <div className="corner-ribbon__inner">
                    {productStatus !== "paid" && <h2 className="corner-ribbon__ribbon">your plan</h2>}
                </div>
            </div>
            
            <div className={`corner-ribbon pricingCard frontPage--paimentSection--div img--rad `}>
                <div >
                    <h2 className='frontPage--section2--text2'>Premium</h2>
                    <h1 className='frontPage--section2--text2'>$6/month</h1>
                    <br/>
                    
                    <h3 className='frontPage--section2--text2'>- up to 500 toys</h3>
                    <h3 className='frontPage--section2--text2'>- up to 50 collections</h3>
                    {/* <h3 className='frontPage--section2--text2'>- email reminder</h3> */}
                    <br/>
                    {/* {logedIn && <Link to='login' className='btn-primary btn'>Log In</Link>} */}
                    {/* {(productStatus === "paid") ? (<button className='btn-primary btn border--radius' onClick={ () => buttonLink('api/stripe/billing_portal') }>Billing portal</button>):((productStatus === "open" || productStatus === "past_due")? <></> : (<button className='btn-primary btn border--radius' onClick={ () => buttonLink('/api/stripe/create_checkout_link_premium') }>Check out</button>))} */}
                    {(productStatus === "paid") && <button className='btn-primary btn border--radius' onClick={ () => buttonLink('api/stripe/billing_portal') }>Billing portal</button>}
                </div>
                <div className="corner-ribbon__inner">
                    {productStatus === "paid" && <h2 className="corner-ribbon__ribbon">your plan</h2>}
                </div>
            </div>
                

            
        </div>
    </div>
  )
}

export default Pricing