import React, { useEffect, useState } from 'react';
import axios from "axios";
import CardBasket from "./CardBasket"
import ListOfToysInABasket from './ListOfToysInABasket';

function BasketsPage() {
    const [toys, setToys] = useState([])
    const [baskets, setBaskets] = useState([1]);
    const [showBasket,setShowBasket] = useState(false)
    const [currentBasket,setCurrentBasket] = useState(1)
    const [triger,setTriger] = useState(true)//start use effect to update baskets when element deleted
    //get all toys
    const getToys = async () => {
        try {
            const config = {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${localStorage.getItem("authToken")}`,
                },
            };
            const {data} = await axios.get('/api/private/getusertoys', config);
            setToys(data)  
        } catch (err) {
            console.error(err);
        }
     
    };
    //get user settings and get array of toysBaskets
    const loadBaskets = async () => {
        try {
            const config = {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${localStorage.getItem("authToken")}`,
                },
              };
            let {data} = await axios.get('/api/private/collectionsForCurrentPlan', config);
            console.log(data)
            setBaskets(data)  
            // console.log(settings)
        } catch (err) {
            console.error(err);
        }
    };
    // const getCollections = async () => {
    //     try {
    //         // console.log('getCollections is running')
    //         const token = await AsyncStorage.getItem('authToken')
    //         const response = await fetch(`${process.env.EXPO_PUBLIC_API_URL}/api/private/collectionsForCurrentPlan`, {
    //             credentials: 'include',
    //             method: 'GET',
    //             headers: { 'Content-Type': 'application/json',
    //             Authorization: `Bearer ${token}`,
    //         },
    //         });
    //         const data = await response.json();
    
    //         setToysBaskets(data)
    //     } catch (err) {
    //         ifFetchFailed()
    //     }     
    //   };

    useEffect(() => {
        getToys();
        loadBaskets()
    }, [triger]);

  

    const printBaskets = baskets.map((item, index) =>{
        return(
            <CardBasket
                item={item}
                key={index}
                setShowBasket={setShowBasket}
                showBasket={showBasket}
                setCurrentBasket={setCurrentBasket}
                currentBasket={currentBasket}
                toys={toys}
                
            />
            // <p key={index}>{item}</p>
        )

    })

  return (
    <div>
        
        
        {showBasket ? 
        <div className='openedBasket'>
            
            <ListOfToysInABasket
                item={currentBasket}
                toys={toys}
                setShowBasket={setShowBasket}
                showBasket={showBasket}
                triger={triger}
                setTriger={setTriger}
            />
        </div>:
        <div className='basketsPage'>
            <h2 className='basketsListText'>Collections:</h2>
            <span className='basketsListInfo'>You can create or rename collections in the Settings. Here, you can add toys to custom collections.</span>
            <div className='printBaskets'>
            
            {printBaskets}
            </div>
        </div>
        
        }
    </div>
  )
}

export default BasketsPage