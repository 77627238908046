import React from 'react'

import PricingNoAuth from './PricingNoAuth';

function FrontPage() {
  return (
    <div className='main'>
        
        <PricingNoAuth/>    
        
        
    </div>
  )
}

export default FrontPage