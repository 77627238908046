import React from 'react';
// import * as FaIcons from 'react-icons/fa';
import * as AiIcons from 'react-icons/ai';
import * as IoIcons from 'react-icons/io';
import * as BsIcons from 'react-icons/bs';



export const SidebarData = [
  {
    title: 'Home',
    path: '/',
    icon: <AiIcons.AiFillHome style={{color: 'white'}}/>,
    cName: 'nav-text'
  },
  {
    title: 'Inventory',
    path: '/inventory',
    icon: <IoIcons.IoIosAlbums style={{color: 'white'}}/>,
    cName: 'nav-text'
  },  
  {
    title: 'Collections',
    path: '/baskets',
    icon: <BsIcons.BsInboxes style={{color: 'white'}}/>,
    cName: 'nav-text'
  },
  {
    title: 'Rotation',
    path: '/rotation',
    icon: <IoIcons.IoMdSwap style={{color: 'white'}}/>,
    cName: 'nav-text'
  }, 
  // {
  //   title: 'Pricing',
  //   path: '/pricing',
  //   icon: <MdIcons.MdPriceCheck style={{color: 'white'}}/>,
  //   cName: 'nav-text'
  // },
  {
    title: 'Settings',
    path: '/settings',
    icon: <IoIcons.IoIosSettings style={{color: 'white'}}/>,
    cName: 'nav-text'
  },
  // {
  //   title: 'About',
  //   path: '/about',
  //   icon: <IoIcons.IoMdPeople />,
  //   cName: 'nav-text'
  // },
  // {
  //   title: 'Contact Us',
  //   path: '/contactus',
  //   icon: <FaIcons.FaEnvelopeOpenText />,
  //   cName: 'nav-text'
  // },
  // {
  //   title: 'Sign Out',
  //   path: '/signout',
  //   icon: <IoIcons.IoIosLogOut />,
  //   cName: 'nav-text'
  // }
];
