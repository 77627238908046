import React from 'react'
import { useState } from 'react'
import Success from './Success';


function RotationToysNumber(props) {
    
    const [formData, setFormData] = useState(
        {
            rotationToysNumber: '',
        }
    )
    const [update, setUpdate] = useState(true)
    const [success, setSuccess] = useState(false)


    function handleChange(event) {
        const {name, value, type, checked} = event.target
        setFormData(prevFormData => {
            return {
                ...prevFormData,
                [name]: type === "checkbox" ? checked : value
            }
        })
    }
    const updateRotationToysNumber = async (rotationToysNumber) => {
        // console.log(base64EncodedImage)
        try {
            const response = await fetch(`/api/private/updateToyRotationNumber/`, {
                credentials: 'include',
                method: 'PUT',
                body: JSON.stringify({ rotationToysNumber: formData.rotationToysNumber }),
                headers: { 'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            },
                
            });
            const data = await response.json()
            console.log(data.msg)
            setSuccess(data.msg)
        } catch (err) {
            console.error(err);
            // setErrMsg('Something went wrong!');
        }
    };

    const handleSubmitFile = (e) => {
        e.preventDefault();
        //if the same value entered it will prevent request
    //    console.log(typeof(formData.rotationToysNumber))
    //    console.log(typeof(props.settings[0].rotationToysNumber))
        if(Number(formData.rotationToysNumber) === props.settings[0].rotationToysNumber){//use Number because of Warining
            return
        }
        updateRotationToysNumber(formData.rotationToysNumber);
        props.setParam(formData.rotationToysNumber)
        setUpdate(!update)
        
        
    };  
    // useEffect(() => {
    //     props.setParam(formData.rotationToysNumber)
    // }, [update]);

  
return (
    <div className='settings--comp'>
        <form onSubmit={handleSubmitFile} className="inventory-screen__form">

            <p className='title--block'>How many toys are you going to rotate?</p>
            {/* <p>Current value {props.settings[0].rotationToysNumber}</p> */}
            {/* <p>Just add more toys if you can not increase the value</p> */}
            <div className="form-group">
                <input
                    type="number"
                    placeholder={props.settings[0].rotationToysNumber}
                    className="border--radius"
                    min='0'
                    max={props.toys.length-props.favarites}
                    onChange={handleChange}
                    name="rotationToysNumber"
                    value={formData.rotationToysNumber}
                    required
                />
                {success && <Success success={success} setSuccess={setSuccess}/>}
                
                <button className="btn btn-primary border--radius" type="submit">
                    Submit
                </button>
            </div> 
        </form>
    </div>
  )
}

export default RotationToysNumber