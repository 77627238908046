import React from "react";
import "./Card.css"
import { useState } from 'react';

import { AiFillStar } from "react-icons/ai";


import { Image } from 'cloudinary-react';

export default function Card(props){

    const [light, setLight] = useState(true);
    const highLightCard = () => {
        setLight(!light)
     
    }



    return(
        <div className="card"    onClick={highLightCard}>
            {/* style={light ? {backgroundColor: "white"}:{backgroundColor: '#bada55'}} */}
            
            
            <Image className="card--img"
                // key={index}
                cloudName={process.env.REACT_APP_CLOUDINARY_NAME}
                publicId={props.item.cloudinaryId}
                width="200"
                height="200"
                crop="fill"
                
                
            />
            
            <div className="card--info" >
            {(props.item.favoriteToy) && <AiFillStar className="roteateStar" size={35} style={{color: 'gold'}}/>}
                {/* <div className="row">
                    <p className="card--location">{props.item.location}</p>
                    <a className="card--google" href={props.item.googleMapsUrl}>View on Google Maps</a>
                </div> */}
                <h1 className="card--title ">{props.index + 1}.{props.item.title}</h1> 
                
                {/* <h5 className="card--date">{props.item.startDate} - {props.item.endDate}</h5> */}
                
                <p className="card--description">{props.item.type}</p>
                {/* <p className="card--description">{props.item.caption}</p> */}
                {/* <input className="input--card3" type="checkbox" id={`rotateToggle${props.index}`}/><label className="lable--card3" for={`rotateToggle${props.index}`}>Toggle</label> */}
                {(props.item.excludedToy) && <p className="card--description" style={{color: 'green'}}>Favorite</p>
                }
                    
                   
                
            </div>
            
        </div>
    )
}