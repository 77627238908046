import React, { useEffect, useState } from 'react';
import "./Card.css"
// import * as CgIcons from 'react-icons/cg';
import { Image } from 'cloudinary-react';

export default function CardItemInABasket(props){
    const [selected,setSelected] = useState(props.listToDelete.includes(props.item))
    
    const selectCard = () => {
        //change state and color if card was selected
        //update state listToDelete
        let index = props.listToDelete.indexOf(props.item)
        let newArray = [...props.listToDelete]
        if(!selected){
            setSelected(true)
            newArray = newArray.concat(props.item)
            props.setListToDelete(newArray)
        }else if(selected){
            setSelected(false)
            newArray.splice(index,1)
            props.setListToDelete(newArray)
        }
        // console.log(newArray)
    }
    useEffect(() => {
        setSelected(false)
    }, [props.triger]);
    useEffect(() => {
        setSelected(props.listToDelete.includes(props.item))
        // console.log(props.listToDelete)
    }, [props.listToDelete,props.item]);

    return(
        <div className={`card ${selected && 'selectedCardInABasket'}`}  onClick={selectCard}>
            <Image className="card--img"
                // key={index}
                cloudName={process.env.REACT_APP_CLOUDINARY_NAME}
                publicId={props.item.cloudinaryId}
                width="136"
                height="136"
                crop="fill"
            />
            <div className="card--info">
                <h1 className="card--title">{props.item.title}</h1>
                <p className="card--description">{props.item.type}</p>
                {(props.item.basket) && <p className="card--description">{props.item.basket.join(' ')}</p>}
            </div>
        </div>
    )
}